import React from 'react';
import { Column } from 'react-table';

import LumeoEvent from 'types/event';

import { useFilterState } from 'components/FilterView/FilterView';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { Icon, IconType } from 'components/Icon/Icon';
import { Table } from 'components/Table/Table';

import { EventEntity } from '../EventEntity/EventEntity';

import { EventsTableDateCell } from './DateCell';
import { EventsTableSeverityCell } from './SeverityCell';
import { EventsTableInfoCell } from './InfoCell';

const columns: Column<LumeoEvent>[] = [
  {
    id: 'severity',
    Cell({ row: { original } }) {
      return <EventsTableSeverityCell event={original} />;
    },
    width: 0,
  },
  {
    id: 'entity',
    Cell({ row: { original } }) {
      return <Icon name={original.object_type as IconType} />;
    },
    width: 0,
  },
  {
    Header: 'Info',
    Cell: EventsTableInfoCell,
  },
  {
    Header: 'Entity',
    Cell({ row }) {
      return <EventEntity event={row.original} />;
    },
  },
  {
    Header: 'Date',
    Cell({ row }) {
      return <EventsTableDateCell event={row.original} />;
    },
    id: 'event_ts',
    sortType: 'date',
    width: 0,
  },
];

export type EventsTableProps = {
  loading: boolean;
};

export function EventsTable({ loading }: EventsTableProps) {
  const { data: allEvents, filteredData: events } = useFilterState();

  const data = React.useMemo(() => events, [events]);

  return (
    <Table
      id="events-overview"
      label="events"
      columns={columns}
      data={data}
      loading={loading}
      emptyMessage={
        <EmptyView
          title={
            allEvents && allEvents.length
              ? 'No events matching the filter criteria found.'
              : 'No events found.'
          }
        />
      }
      sortBy={[
        {
          id: 'event_ts',
          desc: true,
        },
      ]}
    />
  );
}
