import React from 'react';
import classnames from 'classnames/bind';
import { FormProvider } from 'react-hook-form';
import { useToggle } from '@mantine/hooks';

import Deployment, { DeploymentConfiguration } from 'types/deployment';

import { useGateway } from 'gateways/hooks/useGateway';
import { usePipeline } from 'hooks/api/usePipeline';
import { useUpdateDeployment } from 'hooks/api/useDeployment';
import { VideoSource } from 'hooks/api/useVideoSources';

import { ActivePipelineContext } from 'pipelines/context/active_pipeline';
import { Button } from 'components/Button/Button';

import { DeploymentFormActions } from './Actions';
import { DeploymentFormContent } from './Content';
import { DeploymentFormErrorMessage } from './DeploymentFormErrorMessage';
import { DeploymentFormProvider } from './context/deployment_form_provider';
import { useDeploymentFormContent } from './useDeploymentFormContent';
import styles from './Form.module.scss';

const c = classnames.bind(styles);

export type EditDeploymentsFormProps = {
  className?: string;
  deployment: Deployment;
};

export function EditDeploymentForm({
  className,
  deployment,
}: EditDeploymentsFormProps) {
  const { data: gateway, isLoading: isLoadingGateway } = useGateway(
    deployment.gateway_id
  );
  const { data: pipeline, isLoading: isLoadingPipeline } = usePipeline(
    deployment.pipeline_id
  );

  const [isEditing, toggleIsEditing] = useToggle();

  const shouldStartImmediatelyDefault = gateway
    ? gateway.status !== 'offline'
    : true;
  const [shouldStartImmediately, toggleShouldStartImmediately] = useToggle([
    shouldStartImmediatelyDefault,
    !shouldStartImmediatelyDefault,
  ]);

  const formRef = React.useRef<HTMLFormElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const {
    mutate: updateDeployment,
    isLoading,
    error,
  } = useUpdateDeployment({
    onError: () => containerRef.current?.scrollIntoView(),
    onSuccess() {
      toggleIsEditing(false);
    },
  });

  function redeploy(configuration: DeploymentConfiguration) {
    if (!pipeline || !gateway) {
      return;
    }

    const updatedDeployment = new Deployment(
      deployment.id,
      deployment.application_id,
      pipeline.id,
      gateway.id,
      deployment.name,
      configuration
    );
    updatedDeployment.state = shouldStartImmediately ? 'running' : 'stopped';

    updateDeployment(updatedDeployment);
  }

  function handleSubmit() {
    formRef.current?.onSubmit();
  }

  if (isLoadingGateway || isLoadingPipeline) {
    return <>Loading...</>;
  }

  if (!pipeline || !gateway) {
    return null;
  }

  return (
    <div className={c('container', className)} ref={containerRef}>
      <div className={c('edit-form-header')}>
        <Button
          variant={isEditing ? 'secondary' : 'primary'}
          size="small"
          onClick={() => toggleIsEditing()}
        >
          {isEditing ? 'Cancel' : 'Edit configuration'}
        </Button>
      </div>
      <section className={c('body')}>
        <ActivePipelineContext.Provider value={pipeline}>
          <section
            className={c('edit-form')}
            aria-label={`Configure parameters for deployment ${deployment.name}`}
          >
            {error && <DeploymentFormErrorMessage error={error} />}

            <DeploymentFormProvider
              pipeline={pipeline}
              gateway={gateway}
              deployment={deployment}
              readonly={!isEditing}
            >
              <FormContent key={String(isEditing)} deployment={deployment}>
                <DeploymentFormContent onSubmit={redeploy} ref={formRef} />
              </FormContent>
            </DeploymentFormProvider>
          </section>
        </ActivePipelineContext.Provider>
      </section>
      {isEditing && (
        <DeploymentFormActions
          className={c('edit-form-actions')}
          pipeline={pipeline}
          gateway={gateway}
          onCancel={() => toggleIsEditing(false)}
          onSubmit={handleSubmit}
          isDeploying={isLoading}
          startImmediately={shouldStartImmediately}
          onToggleStartImmediately={toggleShouldStartImmediately}
        />
      )}
    </div>
  );
}

type FormContentProps = React.PropsWithChildren<{
  deployment: Deployment;
  videoSource?: VideoSource;
}>;

function FormContent({ deployment, videoSource, children }: FormContentProps) {
  const formMethods = useDeploymentFormContent(deployment, videoSource);
  return <FormProvider {...formMethods}>{children}</FormProvider>;
}
