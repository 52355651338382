import classnames from 'classnames/bind';

import { EditableText } from 'components/EditableText/EditableText';
import { Heading } from 'components/Heading/Heading';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Text } from 'components/Text/Text';

import { PipelineDetailLockButton } from 'pipelines/components/LockButton/LockButton';
import { usePipelineDetailState } from 'pipelines/context/pipeline_detail_view';

import styles from './Header.module.scss';

const c = classnames.bind(styles);

export function PipelineDetailHeader() {
  const {
    pipeline,
    isLoadingLock,
    isLocked,
    saveStatus,
    saveError,
    changeName,
  } = usePipelineDetailState();

  return (
    <div className={c('header')}>
      <Heading level="2" asChild>
        <h1 className={c('header-label')}>
          {isLoadingLock || isLocked ? (
            pipeline.name || 'Untitled Pipeline'
          ) : (
            <EditableText
              defaultValue={pipeline.name || 'Untitled Pipeline'}
              onSave={changeName}
            />
          )}
        </h1>
      </Heading>
      <div className={c('header-info', 'columns valign-center')}>
        {isLocked && (
          <Text inline>
            <PipelineDetailLockButton />
          </Text>
        )}

        {!isLocked && saveStatus === 'loading' && (
          <div className={c('saving-info', 'is-saving')}>
            <InlineNotification icon="running" size="small">
              Saving...
            </InlineNotification>
          </div>
        )}

        {!isLocked && saveStatus === 'success' && (
          <InlineNotification intent="success" icon="check-circle" size="small">
            Saved
          </InlineNotification>
        )}

        {saveStatus === 'error' &&
          saveError &&
          typeof saveError.message === 'function' && (
            <span className={c('saving-info', 'theme danger')}>
              <InlineNotification intent="danger" icon="error" size="small">
                {saveError.message}
              </InlineNotification>
            </span>
          )}
      </div>
    </div>
  );
}
