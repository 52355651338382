import React from 'react';
import c from 'classnames';
import { useFormContext } from 'react-hook-form';

import { DeploymentConfiguration } from 'types/deployment';
import { DeployTimeEditableNode } from 'types/pipeline';

import { sanitizeObject } from 'services/object';
import { useActivePipeline } from 'pipelines/context/active_pipeline';

import { FormMessage } from 'components/FormMessage/FormMessage';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';

import { DeploymentFormNode } from './Node/Node';
import { DeploymentFormSkeleton } from './Skeleton';
import { DeploymentFormWarnings } from './Warnings';
import { sortByNodeIDAndVideoSourcesFirst } from './sort_pipeline_nodes';
import { usePreloadDeploymentForm } from './usePreloadDeploymentForm';

export type DeploymentFormContentProps = {
  onSubmit: (data: DeploymentConfiguration) => void;
  onLink?: () => void;
};

const DEFAULT_DEPLOY_TIME_EDITABLE_NODES: DeployTimeEditableNode[] = [];

function DeploymentFormContent(
  { onSubmit, onLink }: DeploymentFormContentProps,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  const pipeline = useActivePipeline();

  const { isLoading, error } = usePreloadDeploymentForm();

  const methods = useFormContext<DeploymentConfiguration>();

  const { trigger, getValues } = methods;

  React.useImperativeHandle<HTMLFormElement, any>(ref, () => ({
    async onSubmit() {
      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      // Remove null values, particularly in nested objects
      const sanitizedConfiguration = sanitizeObject(getValues());
      onSubmit(sanitizedConfiguration);
    },
    validate: trigger,
  }));

  if (!pipeline) {
    return null;
  }

  if (error) {
    return (
      <FormMessage intent="danger" icon="warning">
        <strong>{error.message || 'An unknown error occurred.'}</strong>
        <br />
        Please try again later.
      </FormMessage>
    );
  }

  if (isLoading) {
    return (
      <SkeletonList min={2} max={3} component={<DeploymentFormSkeleton />} />
    );
  }

  return (
    <form
      className={c({ readonly: methods.formState.isSubmitting })}
      onKeyDown={(event) => event.stopPropagation()}
      onSubmit={(event) => event.preventDefault()}
      ref={ref}
    >
      <DeploymentFormWarnings pipeline={pipeline} onLinkClick={onLink} />

      {pipeline?.nodes
        .sort(sortByNodeIDAndVideoSourcesFirst)
        .map((node) => (
          <DeploymentFormNode
            node={node}
            deployTimeEditableNodes={
              pipeline.deployTimeEditableNodes ||
              DEFAULT_DEPLOY_TIME_EDITABLE_NODES
            }
            key={node.id}
          />
        ))}
    </form>
  );
}

const forwardRef = React.forwardRef<
  HTMLFormElement,
  DeploymentFormContentProps
>(DeploymentFormContent);

export { forwardRef as DeploymentFormContent };
