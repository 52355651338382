import React from 'react';
import classnames from 'classnames/bind';

import { useGateways } from 'hooks/api/useGateways';

import { Card } from 'components/Card/Card';
import { Heading } from 'components/Heading/Heading';
import { AppLink } from 'components/AppLink/AppLink';

import { DashboardNumber } from 'application/views/Overview/Number/Number';

import styles from '../OrganizationUtilization.module.scss';

const c = classnames.bind(styles);

const initial = {
  active: 0,
  offline: 0,
};

export function GatewaysUtilizationCard() {
  const { data: gateways } = useGateways();
  const { active, offline } = React.useMemo(() => {
    if (!gateways) {
      return initial;
    }

    return gateways?.data.reduce(
      (acc, gateway) => {
        if (gateway.isOnline()) {
          acc.active += 1;
        } else if (gateway.isOffline()) {
          acc.offline += 1;
        }
        return acc;
      },
      { ...initial }
    );
  }, [gateways]);

  return (
    <Card className={c('item')}>
      <Heading level="4" asChild>
        <h3>
          <AppLink to="deploy/gateways" className={c('item-link')}>
            Gateways
          </AppLink>
        </h3>
      </Heading>

      <DashboardNumber
        number={active}
        to="deploy/gateways"
        state={{
          filters: {
            status: 'online',
          },
        }}
        status="success"
        offlineNumber={offline}
        offlineState={{
          filters: {
            status: 'offline',
          },
        }}
      />
    </Card>
  );
}
