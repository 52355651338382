import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { pluralize } from 'services/string';
import { useDeleteFiles } from 'files/hooks/useDeleteFiles';
import { useModal } from 'hooks/useModal';

import { BulkAction, FilesSearchContext } from './search_context';

function selectionReducer(state: string[], action: BulkAction): string[] {
  switch (action.type) {
    case 'add':
      return Array.from(new Set([...state, action.id]));
    case 'remove':
      return state.filter((id) => id !== action.id);
    case 'clear':
      return [];
  }
}

export function FilesSearchProvider({ children }: React.PropsWithChildren<{}>) {
  const [selectedFiles, dispatch] = React.useReducer(selectionReducer, []);

  const queryClient = useQueryClient();
  const { open } = useModal();

  const { mutateAsync: deleteFiles, isLoading: isDeleting } = useDeleteFiles({
    onSuccess() {
      dispatch({ type: 'clear' });
      queryClient.invalidateQueries(['files-search']);
    },
  });

  function deleteSelectedFiles() {
    open('confirm-delete', {
      title: `Are you sure you want to delete ${pluralize(selectedFiles.length, 'this', String(selectedFiles.length))} ${pluralize(selectedFiles.length, 'file')}?`,
      onConfirm: () => deleteFiles({ file_ids: selectedFiles }),
    });
  }

  return (
    <FilesSearchContext.Provider
      value={{
        selectedFiles,
        dispatch,
        isDeleting,
        deleteSelectedFiles,
      }}
    >
      {children}
    </FilesSearchContext.Provider>
  );
}
