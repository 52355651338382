import { AppEntityObject } from 'hooks/useEntities';
import {
  alias,
  createModelSchema,
  deserialize,
  identifier,
  primitive,
  serialize,
} from 'serializr';

export type LumeoEventSeverity = 'error' | 'warning' | 'info';

export type LumeoEventObject =
  | 'camera'
  | 'gateway'
  | 'deployment'
  | 'stream'
  | 'other';

export interface ILumeoEvent<TData extends AppEntityObject = AppEntityObject> {
  id?: string;
  created_at?: string; // timestamp
  event_ts: string; // timestamp
  category?: string;
  type?: string;
  severity?: LumeoEventSeverity;
  payload?: string;
  object?: TData;
  object_type?: LumeoEventObject;
  object_id?: string;
  event_type?: string;
}

export default class LumeoEvent<
  TData extends AppEntityObject = AppEntityObject,
> {
  id?: string;
  event_ts: string;
  type?: string;
  payload?: string;
  category?: string;
  severity?: LumeoEventSeverity;
  object?: TData = undefined;
  object_type?: LumeoEventObject;
  object_id?: string;
  event_type?: string;

  constructor(id: string | undefined) {
    this.id = id;
    this.event_ts = new Date().toISOString();
  }

  copy(): LumeoEvent<TData> {
    return deserialize(
      LumeoEvent,
      serialize(this)
    ) as unknown as LumeoEvent<TData>;
  }
}

export class NullLumeoEvent extends LumeoEvent {
  constructor() {
    super(undefined);
  }
}

createModelSchema(LumeoEvent, {
  id: identifier(),
  created_at: primitive(),
  event_ts: primitive(),
  event_type: primitive(),
  category: primitive(),
  type: primitive(),
  severity: primitive(),
  payload: primitive(),
  object_type: alias('object', primitive()),
  object_id: primitive(),
});
