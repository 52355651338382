import classnames from 'classnames/bind';

import { Heading } from 'components/Heading/Heading';
import { Text } from 'components/Text/Text';

import styles from './ModelCard.module.scss';

const c = classnames.bind(styles);

export function ModelCardSkeleton() {
  return (
    <div className={c('skeleton')}>
      <div className={c('model-card')}>
        <div className={c('content')}>
          <div className={c('image', 'skeleton-image')} />
          <Heading className={c('name', 'skeleton-text')} level="4" />
          <Text className={c('description', 'skeleton-text')} />
          <Text className={c('footer', 'skeleton-text')} />
        </div>
      </div>
    </div>
  );
}
