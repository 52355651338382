import { CellProps } from 'react-table';

import LumeoEvent from 'types/event';

import { Text } from 'components/Text/Text';

export function EventsTableInfoCell({ row }: CellProps<LumeoEvent>) {
  if (!row.original) {
    return null;
  }

  const { payload, event_type } = row.original;

  return (
    <>
      <Text>{payload}</Text>
      {event_type && <strong className="label">{event_type}</strong>}
    </>
  );
}
