import classnames from 'classnames/bind';

import { Icon, IconType } from 'components/Icon/Icon';
import {
  ContextMenu,
  ContextMenuOption,
  ContextMenuOptionGroup,
} from 'components/ContextMenu/ContextMenu';

import styles from './InlinePicker.module.scss';

const c = classnames.bind(styles);

export type InlinePickerOptionType = {
  id?: string;
  label: string;
  value: string;
  icon?: IconType;
  variant?: 'danger';
};

export type InlinePickerProps = React.PropsWithChildren<{
  id?: string;
  label: string;
  className?: string;
  icon?: IconType;

  selected?: string;
  onChange?: (value?: string) => void;
  options: InlinePickerOptionType[];
}>;

/** @deprecated Use Select instead. */
export function InlinePicker({
  className,
  options,
  selected,
  icon,
  onChange,
  children,
}: InlinePickerProps) {
  // Compute the label of the selected item
  const selectedOption = options.find((option) => option.value === selected);

  return (
    <ContextMenu
      trigger={
        <div className={c('label', 'text-label', className)}>
          {icon && <Icon className={c('icon')} name={icon} />}
          <span>
            <span>{children}</span>
            <span className={c('selected-value')}>
              {selectedOption && selectedOption.label}
            </span>
          </span>

          <Icon name="chevron-down" className={c('arrow')} size="small" />
        </div>
      }
      align="start"
    >
      <ContextMenuOptionGroup onValueChange={onChange} value={selected}>
        {options.map(({ label, value, ...props }) => (
          <ContextMenuOption {...props} value={value} key={value}>
            {label}
          </ContextMenuOption>
        ))}
      </ContextMenuOptionGroup>
    </ContextMenu>
  );
}
