import { UseQueryOptions, useQueries } from '@tanstack/react-query';

export function useReadFileFromURL(
  url?: string | string[],
  options?: UseQueryOptions<string | undefined, unknown, string>
) {
  const urls = Array.isArray(url) ? url : [url];

  return useQueries({
    queries: urls.map((url) => ({
      queryKey: [url],
      queryFn: () => {
        if (!url) {
          return Promise.reject('Please provide a URL to read from.');
        }

        return fetch(url).then((res) => {
          if (res.status === 200) {
            return res.text();
          }

          return undefined;
        });
      },
      ...options,
      enabled: !!url,
      staleTime: Infinity,
    })),
  });
}
