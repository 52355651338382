import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  Legend,
  LinearScale,
  Tooltip,
  TimeSeriesScale,
} from 'chart.js';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';

import { USER_TIMEZONE as zone } from 'services/timezone';

import { useFilterState } from 'components/FilterView/FilterContext';

ChartJS.register(BarElement, Legend, LinearScale, Tooltip, TimeSeriesScale);

type EventsGraphProps = {
  unit: 'day' | 'hour';
};

export function EventsGraph({ unit }: EventsGraphProps) {
  const { filteredData: events } = useFilterState();

  if (!events.length) {
    return null;
  }

  const timestamps = events.map(({ event_ts }) => DateTime.fromISO(event_ts));
  const duration = { [unit]: 1 };

  const min = DateTime.min(...timestamps).toLocal();
  const max = DateTime.max(...timestamps).toLocal();

  const series: Record<string, Record<string, number>> = {};
  for (let date = min; date < max.plus(duration); date = date.plus(duration)) {
    const interval = date.startOf(unit).toISO();

    if (interval) series[interval] = { info: 0, warning: 0, error: 0 };
  }

  events.forEach(({ event_ts, severity }) => {
    const interval = DateTime.fromISO(event_ts).toLocal().startOf(unit).toISO();
    if (interval) series[interval][severity]++;
  });

  const data = {
    labels: Object.keys(series),
    datasets: [
      {
        label: 'Info',
        backgroundColor: 'hsl(198, 90%, 60%)',
        data: Object.values(series).map(({ info }) => info),
        stack: 'info',
      },
      {
        label: 'Warning',
        backgroundColor: 'hsl(39 100% 57%)',
        data: Object.values(series).map(({ warning }) => warning),
        stack: 'warning',
      },
      {
        label: 'Error',
        backgroundColor: 'hsl(360 75% 60%)',
        data: Object.values(series).map(({ error }) => error),
        stack: 'error',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'timeseries' as const,
        time: { unit },
        adapters: {
          date: { zone },
        },
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
}
