import LumeoEvent from 'types/event';

import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';

export type EventsTableSeverityCellProps = {
  event: LumeoEvent;
};

export function EventsTableSeverityCell({
  event,
}: EventsTableSeverityCellProps) {
  if (!event) {
    return null;
  }

  return <StatusIndicator status={event.severity} size="small" />;
}
