import React from 'react';
import { useCounter, useDeepCompareEffect } from 'react-use';

import { ActiveFilters } from 'types/filters';

import { setQueryStringValue } from 'hooks/useQueryStringState';

import { useFilterState } from 'components/FilterView/FilterView';

export function useFilters(data: any[]) {
  const previousFilters = React.useRef<ActiveFilters>({});
  const previousChangeCount = React.useRef(0);
  const previousFilterChangeCount = React.useRef(0);
  const mounted = React.useRef(false);

  const [dataCounter, { inc: handleDataChange }] = useCounter(0);
  const [filterCounter, { inc: handleFilterChange }] = useCounter(0);

  const [activeFilters, setActiveFilters] = React.useState<ActiveFilters>({});

  const {
    allFilters: filters,
    filteredData,
    setFilteredData,
    searchKeys,
  } = useFilterState();

  const setFilter = React.useCallback(
    (key: string, value: string | undefined) => {
      if (key) {
        const filterItem = filters
          ? filters.find((filter) => {
              const isTimeFilter =
                filter.key === 'time' && (key === 'from' || key === 'to');
              return isTimeFilter || filter.key === key;
            })
          : {};

        setActiveFilters((previousFilters: ActiveFilters) => {
          const properties = key === 'search' ? searchKeys : [key];
          return {
            ...previousFilters,
            [key]: {
              value,
              properties,
              predicateFn: filterItem ? filterItem.predicateFn : null,
            },
          };
        });
      }
    },
    [filters, searchKeys]
  );

  const updateFilter = (key: string, value: string | undefined) => {
    setFilter(key, value);
    setQueryStringValue(key, value);
  };

  const handleSearch = (term: string | undefined) =>
    updateFilter('search', term);

  // initialize filters with query parameters
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    for (const [key, value] of searchParams.entries()) {
      setFilter(key, value);
    }
  }, [setFilter]);

  useDeepCompareEffect(handleDataChange, [data]);
  useDeepCompareEffect(handleFilterChange, [activeFilters]);

  React.useEffect(() => {
    const didFiltersChange =
      filterCounter !== previousFilterChangeCount.current;
    const didDataChange = dataCounter !== previousChangeCount.current;

    // Skip filter mechanism when it's not the initial filter (query params)
    // and filters or data did not change.
    if (mounted.current && !didFiltersChange && !didDataChange) {
      return;
    }

    const filters = Object.entries(activeFilters);

    mounted.current = true;
    previousFilters.current = activeFilters;
    previousChangeCount.current = dataCounter;
    previousFilterChangeCount.current = filterCounter;

    if (!filters.length) {
      setFilteredData(data);
      return;
    }

    const filteredData = data.filter((item) => {
      let isMatch = false;
      const alreadyMatched: string[] = [];

      for (const [key, { value, properties, predicateFn }] of filters) {
        // run check if same key hasn't been checked yet
        if (alreadyMatched.includes(key)) break;

        // custom filter predicateFn functions take precedence
        if (predicateFn) {
          isMatch = predicateFn(item);
        } else {
          // only run this if every property in the list is valid for this entity
          if (!properties.every((property: string) => property in item)) break;

          for (const property of properties) {
            if (key === 'search') {
              isMatch = item[property]
                .toUpperCase()
                .includes(value?.toUpperCase());
            } else {
              if (item[property]) {
                // empty values always count as a match
                if (typeof item[property] === 'string') {
                  isMatch =
                    !value ||
                    item[property]?.toUpperCase() === value?.toUpperCase();
                }
              } else {
                // if a piece of data is null, it may be labelled as 'unknown'
                isMatch = value === 'unknown' || !value;
              }
            }

            // return on the first data item property that matches the filter
            if (isMatch) {
              alreadyMatched.push(key);
              break;
            }
          }
        }

        // kill the check for the current item if
        // it doesn't match any one of the filters
        if (!isMatch) return false;
      }

      return isMatch;
    });

    setFilteredData(filteredData);
  }, [activeFilters, data, setFilteredData, dataCounter, filterCounter]);

  return {
    activeFilters,
    onSearch: handleSearch,
    onFilter: updateFilter,
    filteredData,
  };
}
