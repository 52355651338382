import React from 'react';
import classNames from 'classnames/bind';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@mantine/core';

import { ApplicationParams } from 'application/types/application_params';
import Deployment, { DeploymentConfiguration } from 'types/deployment';

import { ActivePipelineContext } from 'pipelines/context/active_pipeline';
import { useToggle } from '@mantine/hooks';

import APIError from 'types/api_error';
import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';

import { pluralize } from 'services/string';
import { useGateway } from 'gateways/hooks/useGateway';
import { useUpdateDeployment } from 'hooks/api/useDeployment';

import { Accordion } from 'components/Accordion/Accordion';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';

import { DeploymentFormActions } from '../Actions';
import { DeploymentFormContent } from '../Content';
import { DeploymentFormErrorMessage } from '../DeploymentFormErrorMessage';
import { DeploymentFormProvider } from '../context/deployment_form_provider';
import { useDeploymentFormContent } from '../useDeploymentFormContent';
import { usePreloadDeploymentForm } from '../usePreloadDeploymentForm';
import styles from '../Form.module.scss';

const c = classNames.bind(styles);

export type UpdateDeploymentsFormStep2Props = {
  pipeline: Pipeline;
  deployments?: Deployment[];
  count: number;
  isLoading: boolean;
  isError: boolean;
  onSuccess?: (deployments: Deployment[]) => void;
};

export function UpdateDeploymentsFormStep2({
  pipeline,
  deployments,
  count,
  isLoading,
  isError,
  onSuccess,
}: UpdateDeploymentsFormStep2Props) {
  const { applicationID } = useParams<ApplicationParams>();

  const { isLoading: isLoadingFormComponents, error } =
    usePreloadDeploymentForm();

  const [errors, setErrors] = React.useState<
    Record<Deployment['id'], APIError | undefined>
  >({});

  const [shouldStartImmediately, toggleShouldStartImmediately] = useToggle([
    true,
    false,
  ]);

  const formRefs = React.useRef<Array<HTMLFormElement | null>>([]);

  const { mutateAsync: updateDeployment, isLoading: isUpdating } =
    useUpdateDeployment({
      onSuccess() {
        if (!deployments) {
          return;
        }

        onSuccess?.(deployments);
      },
    });

  function redeploy(
    deployment: Deployment,
    gateway: Gateway,
    configuration: DeploymentConfiguration = {}
  ) {
    if (!pipeline || !applicationID) {
      return;
    }

    const { id, name } = deployment;
    let state = shouldStartImmediately
      ? ('running' as const)
      : ('stopped' as const);

    if (gateway.status === 'offline') {
      state = 'stopped';
    }

    const updatedDeployment = new Deployment(
      id,
      applicationID,
      pipeline.id,
      gateway.id,
      name,
      configuration
    );
    updatedDeployment.state = state;

    updateDeployment(updatedDeployment).catch((error) =>
      // Form errors
      setErrors((previous) => ({ ...previous, [updatedDeployment.id]: error }))
    );
  }

  function submitDeployments() {
    formRefs.current?.forEach((form) => form?.onSubmit());
  }

  if (!pipeline) {
    return null;
  }

  if (isError) {
    return (
      <FormMessage intent="danger" icon="warning">
        <strong>{error?.message || 'An unknown error occurred.'}</strong>
        <br />
        Please try again later.
      </FormMessage>
    );
  }

  if (isLoading || isLoadingFormComponents || !deployments) {
    return (
      <SkeletonList
        min={count || 2}
        max={count || 2}
        component={<Accordion label={<Skeleton w="50%" />} disabled />}
      />
    );
  }

  return (
    <DeploymentFormProvider pipeline={pipeline}>
      <div className={c('container')}>
        <section className={c('body')}>
          <ActivePipelineContext.Provider value={pipeline}>
            {deployments.map((deployment, index) => (
              <ForwardInnerRef
                deployment={deployment}
                onSubmit={redeploy}
                error={errors[deployment.id]}
                isSingleDeployment={deployments.length === 1}
                key={deployment.id}
                ref={(el: any) => (formRefs.current[index] = el)}
              />
            ))}
          </ActivePipelineContext.Provider>
        </section>
      </div>

      <DeploymentFormActions
        pipeline={pipeline}
        onSubmit={submitDeployments}
        isDeploying={isUpdating}
        startImmediately={shouldStartImmediately}
        onToggleStartImmediately={toggleShouldStartImmediately}
        deployments={deployments}
        buttonText={`Update ${deployments.length} ${pluralize(deployments.length, 'deployment')}`}
        isUpdateDeploymentsForm
      />
    </DeploymentFormProvider>
  );
}

type InnerProps = {
  deployment: Deployment;
  error?: APIError;
  onSubmit: (
    deployment: Deployment,
    gateway: Gateway,
    configuration: DeploymentConfiguration
  ) => void;
  isSingleDeployment?: boolean;
};

function Inner(
  { deployment, error, onSubmit, isSingleDeployment }: InnerProps,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  const { data: gateway, isLoading } = useGateway(deployment.gateway_id);

  const formMethods = useDeploymentFormContent(deployment);

  function handleSubmit(configuration: DeploymentConfiguration) {
    if (!gateway) {
      return;
    }

    onSubmit(deployment, gateway, configuration);
  }

  if (isLoading) {
    return (
      <Accordion
        label={<Skeleton w="50%">{deployment.name}</Skeleton>}
        disabled
      />
    );
  }

  const form = (
    <section
      className={c('field', 'deployment')}
      aria-label={`Configure parameters for deployment ${deployment.name}`}
    >
      {error && <DeploymentFormErrorMessage error={error} />}

      <FormProvider {...formMethods}>
        <DeploymentFormContent onSubmit={handleSubmit} ref={ref} />
      </FormProvider>
    </section>
  );

  if (isSingleDeployment) {
    return form;
  }

  return <Accordion label={deployment.name || deployment.id}>{form}</Accordion>;
}

const ForwardInnerRef = React.forwardRef<HTMLFormElement, InnerProps>(Inner);
