import { useQueryClient } from '@tanstack/react-query';

import Application from 'types/application';
import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';
import { Tag } from 'types/tag';

import { useAppQueryClient } from './useAppQueryClient';

export type AppEntityObject = {
  id?: string;
  name?: string;
  created_at?: string;
  updated_at?: string;
};

export type TaggableEntityObject = {
  id: string;
  tags: Tag['id'][];
};

export type GlobalEntity =
  | 'applications'
  | 'organizations'
  | 'marketplace-models';

export type AppEntity =
  | 'pipelines'
  | 'deployments'
  | 'gateways'
  | 'cameras'
  | 'streams'
  | 'files'
  | 'models';

/** @deprecated */
export function useEntityList<T extends AppEntityObject>(
  entity: GlobalEntity
): T[] {
  const queryClient = useQueryClient();

  if (!entity) {
    return [];
  }

  return queryClient.getQueryData<T[]>([entity]) || [];
}

/** @deprecated */
export function useEntity<T extends AppEntityObject>(
  entity: GlobalEntity,
  id?: string
): T | undefined {
  const list = useEntityList<T>(entity);

  if (!id) {
    return undefined;
  }

  return list.find((entity) => entity.id === id);
}

/** @deprecated */
export function useAppEntityList<T extends AppEntityObject>(
  entity?: AppEntity
): T[] {
  const queryClient = useAppQueryClient();

  if (!entity) {
    return [];
  }

  return queryClient.getQueryData<T[]>([entity]) || [];
}

/** @deprecated */
export function useAppEntity<T extends AppEntityObject>(
  entity: AppEntity,
  id?: string
): T | undefined {
  const list = useAppEntityList<T>(entity);

  if (!id) {
    return undefined;
  }

  return list.find((entity) => entity.id === id);
}

/** @deprecated */
export const useApplicationInstance = (applicationID?: string) => {
  return useEntity<Application>('applications', applicationID);
};

/** @deprecated */
export const usePipelineInstance = (pipelineID?: string) => {
  return useAppEntity<Pipeline>('pipelines', pipelineID);
};

/** @deprecated */
export const useDeploymentInstance = (deploymentID?: string) => {
  return useAppEntity<Deployment>('deployments', deploymentID);
};

/** @deprecated */
export const usePipelineInstanceOfDeployment = (deploymentID?: string) => {
  const deployment = useDeploymentInstance(deploymentID);
  return usePipelineInstance(deployment?.pipeline_id);
};

/** @deprecated */
export const useGatewayInstance = (gatewayID?: string) => {
  return useAppEntity<Gateway>('gateways', gatewayID);
};
