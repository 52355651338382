import React from 'react';
import classnames from 'classnames/bind';

import AIModel from 'types/ai_model';

import { Button } from 'components/Button/Button';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { ModelCard } from 'analytics-library/components/ModelCard/ModelCard';
import { ModelCardSkeleton } from 'analytics-library/components/ModelCard/ModelCardSkeleton';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { useAdvancedSearch } from 'components/AdvancedSearch/services/AdvancedSearchContext';

import { ReactComponent as EmptyIllustration } from 'analytics-library/images/empty-models-illustration.svg';
import styles from './ModelGrid.module.scss';

const c = classnames.bind(styles);

export function ModelGrid() {
  const {
    data: models,
    filteredData: filteredModels,
    isLoading,
  } = useAdvancedSearch<AIModel>();

  const [model] = models;
  const isUser = model ? !model.isMarketplaceModel() : true;

  if (!models || isLoading) {
    return (
      <div className={c('model-grid')}>
        <SkeletonList component={<ModelCardSkeleton />} min={7} max={22} />
      </div>
    );
  }

  if (!models.length) {
    return (
      <div className={c('empty')}>
        <EmptyView image={EmptyIllustration} title={'No models available.'}>
          {isUser && (
            <Button className={c('button')} variant="secondary" to="new">
              Add model
            </Button>
          )}
        </EmptyView>
      </div>
    );
  }

  if (!filteredModels.length) {
    return (
      <div className={c('empty')}>
        <EmptyView image={EmptyIllustration}>
          No models matching your search input found.
        </EmptyView>
      </div>
    );
  }

  return (
    <div className={c('model-grid')}>
      {filteredModels.map((model) => {
        return <ModelCard model={model} key={model.id} />;
      })}
    </div>
  );
}
