import React from 'react';
import classnames from 'classnames/bind';

import { Filter, TimeFilterOption } from 'types/filters';

import { TimeUnit, subtractFromDate } from 'services/date';

import { useFilters } from 'hooks/useFilters';
import { useQueryStringState } from 'hooks/useQueryStringState';

import { DateTimeRangePicker } from 'components/DateTimeRangePicker/DateTimeRangePicker';
import { Icon } from 'components/Icon/Icon';
import { InlinePicker } from 'components/InlinePicker/InlinePicker';
import { TitledModal } from 'components/TitledModal/TitledModal';

import FilterContext, { useFilterState } from './FilterContext';
import styles from './Filters.module.scss';

const c = classnames.bind(styles);

const defaultTimeFilter = TimeFilterOption.HOUR;

export type FiltersProps = {
  timeFilters?: Filter | undefined | false;
  onTimeFilterChange?: (
    filter: TimeFilterOption | undefined,
    from: number,
    until: number
  ) => void;
  sort?: any;
  disableSearch?: boolean;
  preventFilter?: boolean;
};

export function Filters({
  timeFilters,
  onTimeFilterChange,
  sort,
  disableSearch,
}: FiltersProps) {
  const timeoutId = React.useRef<any>();

  const { data, entity, filters, isRangePickerOpen, setIsRangePickerOpen } =
    useFilterState();
  const { onSearch, onFilter, activeFilters } = useFilters(data);

  const [searchTerm, setSearchTerm] = React.useState<string>(
    activeFilters.search?.value || ''
  );

  const [timeFilter, setTimeFilter] = React.useState<
    TimeFilterOption | undefined
  >();
  const [timeFilterFrom, setTimeFilterFrom] = useQueryStringState('from');
  const [timeFilterUntil, setTimeFilterUntil] = useQueryStringState('to');

  const handleSearch: React.ChangeEventHandler = (event) => {
    clearTimeout(timeoutId.current);

    const { value } = event.target as HTMLInputElement;
    setSearchTerm(value);
    timeoutId.current = setTimeout(() => onSearch(value), 100);
  };

  const timeFilterFromByFilter = (
    filter: TimeFilterOption | undefined
  ): Date | undefined =>
    filter
      ? {
          [TimeFilterOption.HOUR]: subtractFromDate(
            new Date(),
            1,
            TimeUnit.HOUR
          ),
          [TimeFilterOption.DAY]: subtractFromDate(
            new Date(),
            24,
            TimeUnit.HOURS
          ),
          [TimeFilterOption.WEEK]: subtractFromDate(
            new Date(),
            1,
            TimeUnit.WEEK
          ),
          [TimeFilterOption.CUSTOM]: new Date(),
        }[filter]
      : undefined;

  const handleTimeFilterChange = (filter: TimeFilterOption | undefined) => {
    const now = new Date();
    let from = null;

    if (filter === TimeFilterOption.CUSTOM) {
      from = subtractFromDate(now, 1, TimeUnit.DAY);
    } else {
      from = timeFilterFromByFilter(filter);
    }

    setTimeFilterFrom(from ? +from : undefined);
    setTimeFilterUntil(+now);
    setTimeFilter(filter);
  };

  React.useEffect(() => {
    const paramSearch = activeFilters.search?.value;

    if (paramSearch) {
      setSearchTerm(paramSearch);
    }
  }, [activeFilters]);

  React.useEffect(() => {
    if (onTimeFilterChange) {
      const from = +timeFilterFrom;
      const until = +timeFilterUntil;

      // set 'custom' filter whenever loading initially with query parameters set
      if (!timeFilter) {
        if (from || until) {
          setTimeFilter(TimeFilterOption.CUSTOM);
        } else {
          setTimeFilter(defaultTimeFilter);
          const from = timeFilterFromByFilter(defaultTimeFilter);
          setTimeFilterFrom(from ? +from : undefined);
          setTimeFilterUntil(+new Date());
        }
      }

      onTimeFilterChange(timeFilter, from, until);
    }
  }, [
    onTimeFilterChange,
    timeFilter,
    timeFilterFrom,
    timeFilterUntil,
    setTimeFilterFrom,
    setTimeFilterUntil,
  ]);

  return (
    <section className={c('filter-bar')}>
      <div className={c('slot', 'search')}>
        {!disableSearch && (
          <>
            <label htmlFor="filterbar_search" className="sr-only">
              Search {entity ? entity : 'this view'}
            </label>
            <label htmlFor="filterbar_search">
              <Icon name="search" className={c('search-icon')} />
            </label>
            <input
              id="filterbar_search"
              className={c('search-input')}
              placeholder={`Search ${entity || 'this view'}...`}
              value={searchTerm}
              onChange={handleSearch}
              type="search"
            />
          </>
        )}
      </div>
      <div className={c('slot', 'filters')}>
        {timeFilters && (
          <>
            <InlinePicker
              id="filter-time"
              label="Filter by time"
              options={timeFilters.options}
              selected={timeFilter}
              onChange={(value) =>
                handleTimeFilterChange(value as TimeFilterOption)
              }
            >
              Time
            </InlinePicker>

            {isRangePickerOpen && (
              <TitledModal
                title="Select a timespan"
                onRequestClose={() => setIsRangePickerOpen(false)}
              >
                <DateTimeRangePicker
                  start={new Date(timeFilterFrom)}
                  setStart={(from) => setTimeFilterFrom(+from)}
                  end={new Date(timeFilterUntil)}
                  setEnd={(until) => setTimeFilterUntil(+until)}
                  onRequestClose={() => setIsRangePickerOpen(false)}
                />
              </TitledModal>
            )}
          </>
        )}

        {filters && filters.length
          ? filters.map(
              ({ key, type, label, selected: initial, onChange, ...rest }) => {
                const selected =
                  activeFilters && activeFilters[key]
                    ? activeFilters[key].value
                    : initial;
                const props = {
                  id: `filter-${key}`,
                  key,
                  selected,
                  onChange: (value: string | undefined) => {
                    onFilter(key, value);

                    if (onChange) {
                      onChange(value);
                    }
                  },
                  ...rest,
                };

                return (
                  <InlinePicker
                    {...props}
                    label={`Filter by ${label}`}
                    selected={selected}
                    key={key}
                  >
                    {label}
                  </InlinePicker>
                );
              }
            )
          : null}
      </div>
      <div className={c('slot', 'sort', 'columns halign-end')}>{sort}</div>
    </section>
  );
}

export { FilterContext };
