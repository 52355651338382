import React from 'react';

import { Filter } from 'types/filters';

type FilterContextProps<T> = {
  data: T[];
  filteredData: T[];
  setFilteredData: (newData: any) => void;
  entity?: string;
  filters: Filter[];
  allFilters: any[];
  searchKeys: string[];
  isRangePickerOpen: boolean;
  setIsRangePickerOpen: (newState: boolean) => void;
};

const FilterContext: React.Context<FilterContextProps<any>> =
  React.createContext({} as FilterContextProps<any>);

function useFilterState() {
  const context = React.useContext(FilterContext);
  if (context === undefined) {
    throw new Error(
      '`useFilterState` must be used within a `FilterView` component.'
    );
  }
  return context;
}

export default FilterContext;
export { useFilterState };
