import React from 'react';
import classnames from 'classnames/bind';

import { DeploymentState } from 'types/deployment';
import { StreamState } from 'types/stream';

import { formatPretty } from 'services/string';

import { Pill, PillIntent, PillProps } from 'components/Pill/Pill';
import { IconType } from 'components/Icon/Icon';

import styles from './StatusIndicator.module.scss';

const c = classnames.bind(styles);

export type FileStatus = 'streaming' | 'uploading';

export type SuccessStatus =
  | 'online'
  | 'running'
  | 'success'
  | 'valid'
  | DeploymentState
  | FileStatus;
export type NeutralStatus = 'info' | 'unknown' | 'offline';
export type ErrorStatus = 'error' | 'warning' | 'unauthorized' | 'invalid';
export type IntermediateStatus = 'stopping' | 'starting' | 'pending';

export type Status =
  | StreamState
  | SuccessStatus
  | NeutralStatus
  | IntermediateStatus
  | ErrorStatus
  | 'progress';

export type StatusIndicatorProps = {
  className?: string;
  size?: 'small' | 'xsmall';
  status?: Status;
} & React.HTMLAttributes<HTMLSpanElement>;

const STATUS_THEME_MAP: Record<Status, PillIntent> = {
  unknown: 'default',
  unauthorized: 'default',

  online: 'success',
  success: 'success',
  running: 'success',
  valid: 'success',

  deploying: 'info',
  progress: 'info',
  streaming: 'info',
  uploading: 'info',
  info: 'info',

  starting: 'warning',
  stopping: 'warning',
  stopped: 'warning',
  warning: 'warning',
  pending: 'warning',

  restart_waiting: 'pending',

  error: 'danger',
  interrupted: 'danger',
  offline: 'danger',
  invalid: 'danger',
};

const STATUS_ICON_MAP: Record<string, IconType> = {
  deploying: 'running',
  streaming: 'running',
  uploading: 'running',
  stopping: 'running',
  starting: 'running',
  pending: 'running',
  restart_waiting: 'running',
  progress: 'running',
  running: 'check',
  success: 'check',
  error: 'cancel',
  offline: 'cancel',
  interrupted: 'cancel',
  valid: 'check',
  invalid: 'danger',
};

const STATUS_VARIANT_MAP: Record<PillIntent, PillProps['variant']> = {
  success: 'primary',
  danger: 'primary',
  info: 'secondary',
  warning: 'secondary',
  pending: 'secondary',
  default: 'secondary',
};

// States with manual formatting
export const STATE_FORMATTED: Partial<Record<Status, string>> = {
  restart_waiting: 'Waiting to restart',
};

export function StatusIndicator({
  className,
  status,
  size,
  ...props
}: StatusIndicatorProps) {
  if (!status) {
    return null;
  }

  const intent = STATUS_THEME_MAP[status];
  const icon = STATUS_ICON_MAP[status] || (status as IconType);
  const variant = STATUS_VARIANT_MAP[intent];

  return (
    <Pill
      {...props}
      icon={icon}
      className={c('indicator', `status-${status}`, className, size)}
      variant={size ? variant : 'secondary'}
      iconClassName={c('icon')}
      intent={intent}
      aria-label={status}
    >
      {!size && <span>{STATE_FORMATTED[status] ?? formatPretty(status)}</span>}
    </Pill>
  );
}
