import { MantineThemeOverride } from '@mantine/core';

const fontFamily = "'Roboto', 'Helvetica Neue', 'Arial', sans-serif";

export const LUMEO_THEME: MantineThemeOverride = {
  fontFamily,

  respectReducedMotion: true,

  white: 'hsl(var(--ui-fg))',
  black: 'hsl(var(--ui-text-highContrast))',

  colors: {
    blue: [
      '#E7F6FE',
      '#BBE6FB',
      '#8FD6F9',
      '#64C6F7',
      '#38B6F5',
      '#0DA6F2',
      '#0A85C2',
      '#086491',
      '#054261',
      '#032130',
    ],
    gray: [
      'hsl(var(--ui-2))',
      'hsl(var(--ui-3))',
      'hsl(var(--ui-5))',
      'hsl(var(--ui-6))',
      'hsl(var(--ui-7))',
      'hsl(var(--ui-8))',
      'hsl(var(--ui-9))',
      'hsl(var(--ui-10))',
      'hsl(var(--ui-11))',
      'hsl(var(--ui-12))',
    ],
    dark: [
      'hsl(var(--ui-2))',
      'hsl(var(--ui-3))',
      'hsl(var(--ui-5))',
      'hsl(var(--ui-6))',
      'hsl(var(--ui-7))',
      'hsl(var(--ui-8))',
      'hsl(var(--ui-9))',
      'hsl(var(--ui-10))',
      'hsl(var(--ui-11))',
      'hsl(var(--ui-12))',
    ],
  },
  primaryColor: 'blue',
  primaryShade: 7,

  fontSizes: {
    xs: 'var(--text-sm)',
    sm: 'var(--text-md)',
    md: 'var(--text-lg)',
    lg: 'var(--text-xl)',
    xl: 'var(--text-2xl)',
  },
  headings: {
    fontFamily,
    fontWeight: '500',
    sizes: {
      h1: {
        fontSize: 'var(--text-2xl)',
        lineHeight: 'var(--lh-2xl)',
      },
      h2: {
        fontSize: 'var(--text-xl)',
        lineHeight: 'var(--lh-xl)',
      },
      h3: {
        fontSize: 'var(--text-lg)',
        lineHeight: 'var(--lh-lg)',
      },
      h4: {
        fontSize: 'var(--text-md',
        lineHeight: 'var(--lh-md)',
      },
    },
  },

  radius: {
    xs: 'var(--radius-xs)',
    sm: 'var(--radius-sm)',
    md: 'var(--radius-md)',
    lg: 'var(--radius-lg)',
    xl: 'var(--radius-full)',
  },

  defaultRadius: 'md',

  spacing: {
    xs: 'var(--size-xs)',
    sm: 'var(--size-sm)',
    md: 'var(--size-md)',
    lg: 'var(--size-lg)',
    xl: 'var(--size-xl)',
  },

  focusClassName: 'focus-ring',
};
