import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

import { Button } from 'components/Button/Button';

import styles from './DateTimeRangePicker.module.scss';

export type DateTimeRangePickerProps = {
  start: Date;
  setStart: (start: Date) => void;
  end: Date;
  setEnd: (end: Date) => void;
  onRequestClose: () => void;
};

/** @deprecated Use <DatePickerInput /> instead. */
export function DateTimeRangePicker({
  start,
  setStart,
  end,
  setEnd,
  onRequestClose,
}: DateTimeRangePickerProps) {
  const timeFormat = 'h:mm a';
  const timeIntervals = 15;
  const timeCaption = 'time';
  const dateFormat = 'MMMM d, yyyy h:mm aa';

  return (
    <div className={styles.picker}>
      <section className={styles.pickers}>
        <section>
          <div>Start:</div>

          <DatePicker
            selected={start}
            onChange={setStart}
            showTimeSelect
            inline
            maxDate={end}
            timeFormat={timeFormat}
            timeIntervals={timeIntervals}
            timeCaption={timeCaption}
            dateFormat={dateFormat}
          />
        </section>

        <section>
          <div>End:</div>

          <DatePicker
            selected={end}
            onChange={setEnd}
            showTimeSelect
            inline
            minDate={start}
            timeFormat={timeFormat}
            timeIntervals={timeIntervals}
            timeCaption={timeCaption}
            dateFormat={dateFormat}
          />
        </section>
      </section>

      <div className="columns halign-end">
        <Button onClick={onRequestClose} className={styles.button}>
          Apply
        </Button>
      </div>
    </div>
  );
}
