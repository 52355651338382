import { InlinePickerOptionType } from 'components/InlinePicker/InlinePicker';
import { IconType } from 'components/Icon/Icon';

export enum TimeFilterOption {
  HOUR = '1-hour',
  DAY = '24-hours',
  WEEK = '1-week',
  CUSTOM = 'custom',
}

export enum FilterTypes {
  RADIO = 'radio',
  DROPDOWN = 'dropdown',
}

export type ActiveFilter = {
  value: string | undefined;
  properties: string[];
  predicateFn?: <T>(item: T) => boolean;
  onChange?: <T = string>(newValue: T) => void;
};

export type ActiveFilters = {
  [key: string]: ActiveFilter;
};

export type FilterOnChange<T> = (newFilter: T) => void;

export type Filter<T = any> = {
  key: string;
  icon?: IconType;
  type?: FilterTypes;
  label: string;
  options: InlinePickerOptionType[];
  onChange?: FilterOnChange<T>;
  selected?: string;
};
