import React from 'react';

import Deployment from 'types/deployment';
import { ILumeoEvent, LumeoEventObject } from 'types/event';

import {
  AttributeList,
  AttributeListItem,
} from 'components/AttributeList/AttributeList';
import { IconType } from 'components/Icon/Icon';
import { TextAppLink } from 'components/TextAppLink/TextAppLink';
import { Text } from 'components/Text/Text';

export type EventEntityProps = React.PropsWithChildren<{
  className?: string;
  event: ILumeoEvent;
  deployment?: Deployment;
}>;

const EVENT_ENTITY_URL_MAP: Record<LumeoEventObject, string> = {
  camera: 'deploy',
  deployment: 'deploy',
  gateway: 'deploy',
  stream: 'deploy',
  other: '',
};

export function EventEntity({
  className,
  event,
  deployment,
}: EventEntityProps) {
  const { object, object_id, object_type } = event;

  if (!object_type || !object_id) {
    return null;
  }

  const objectURL = `${EVENT_ENTITY_URL_MAP[object_type]}/${object_type}s`;

  return (
    <AttributeList className={className}>
      <AttributeListItem
        icon={object_type as IconType}
        label={object_type as string}
      >
        {object || deployment?.name ? (
          <TextAppLink to={`/${objectURL}/${object_id}`}>
            {object?.name || deployment?.name}
          </TextAppLink>
        ) : (
          <Text inline>Deleted {object_type}</Text>
        )}
      </AttributeListItem>
    </AttributeList>
  );
}
