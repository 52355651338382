import React from 'react';

import { ReteInputProps } from 'pipelines/services/rete/controls/input';

import { useActivePipeline } from 'pipelines/context/active_pipeline';
import { useCurrentNode } from 'deployments/hooks/useCurrentNode';
import { useMarketplaceModels, useModels } from 'hooks/api/useModels';
import { useReadFileFromURL } from 'hooks/useReadFileFromURL';

import { MultiSelectInput } from 'components/MultiSelectInput/MultiSelectInput';

type UpstreamModelLabelsProps = ReteInputProps;

export function UpstreamModelLabels({
  id,
  onChange,
  value,
}: UpstreamModelLabelsProps) {
  const { nodeGraph } = useActivePipeline();
  const { nodeID } = useCurrentNode();
  const { data: userModels } = useModels();
  const { data: marketplaceModels } = useMarketplaceModels();

  const models = React.useMemo(
    () => (userModels ?? []).concat(marketplaceModels ?? []),
    [userModels, marketplaceModels]
  );

  const upstreamNodes = nodeGraph.get(nodeID)?.allUpstreamNodes() || [];
  const modelNodes = [...upstreamNodes].filter(({ data }) => data.model_id);
  const labelModels = models.filter(({ id }) =>
    modelNodes.find(({ data }) => id === data.model_id)
  );
  const labelFileURLs = labelModels
    .map(({ labels_file_url }) => labels_file_url as string)
    .filter(Boolean);

  const fileResults = useReadFileFromURL(labelFileURLs);
  const labelStrings = fileResults
    .map(({ data }) => data as string)
    .filter(Boolean);
  const labels = labelStrings.flatMap((labels) => labels.split('\n'));
  const options = labels.map((value) => ({ value }));

  // Dynamic node expects single string
  const handleValueChange = React.useCallback(
    (value: string[] | null) => onChange?.(value?.join(',') || value),
    [onChange]
  );

  const selected = value ? String(value).split(',') : [];

  return (
    <MultiSelectInput
      id={id}
      options={options}
      onChange={handleValueChange}
      selected={selected}
      allowCreate
    />
  );
}
