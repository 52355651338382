import React from 'react';
import classnames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import { VideoSource } from 'hooks/api/useDeploymentsVideoSources';

import { useCamera } from 'cameras/hooks/useCamera';
import { useHasAccess } from 'hooks/useHasAccess';
import { useMqttStreamsState } from 'streams/hooks/useMqttStreamsState';
import { useStreams } from 'streams/hooks/useStreams';
import { useUpdateCamera } from 'cameras/hooks/useUpdateCamera';

import { ApplicationParams } from 'application/views/App/AppContainer';
import { NotFound } from 'views/NotFound/NotFound';

import { DeploymentListSmall } from 'components/DeploymentListSmall/DeploymentListSmall';
import {
  DetailView,
  DetailViewHeader,
  DetailViewStatus,
} from 'components/DetailView/DetailView';
import { Loader } from 'components/Loader/Loader';
import { Tabs, TabsButton, TabsContent, TabsList } from 'components/Tabs/Tabs';
import { VideoSourceSnapshot } from 'components/VideoSourceSnapshot/VideoSourceSnapshot';
import { FileList } from './components/FileList/FileList';
import { SharedStreamsList } from 'streams/components/SharedStreams/SharedStreamsList';

import { CameraDetailEntities } from './CameraDetailEntities';
import { CameraDetailStatus } from './CameraDetailStatus';
import { CameraDetailSettings } from './components/CameraDetailSettings/CameraDetailSettings';
import { CameraDetailStreams } from './components/CameraDetailStreams/CameraDetailStreams';

import styles from './CameraDetail.module.scss';

const c = classnames.bind(styles);

export type CameraParams = ApplicationParams & {
  cameraID: string;
};

export function CameraDetail() {
  const { applicationID, cameraID } = useParams<CameraParams>();

  const [hasAccess] = useHasAccess();
  const [isRenaming, toggleIsRenaming] = useToggle();

  const { data: camera, isLoading: isCameraLoading } = useCamera(cameraID);
  const { mutate: updateCamera } = useUpdateCamera();

  const { data: streams, isLoading: isStreamsLoading } = useStreams(
    ['streams', 'camera-detail'],
    {
      sources: ['camera_stream', 'uri_stream'],
      camera_ids: cameraID ? [cameraID] : [],
    },
    {
      enabled: Boolean(cameraID),
    }
  );

  useMqttStreamsState(streams ? streams.data.map(({ id }) => id) : []);

  if (isCameraLoading) {
    return <Loader text="Loading camera..." />;
  }

  const streamIds = streams?.data.map((stream) => stream.id) ?? [];

  let previewSource: VideoSource | null = null;
  if (camera?.conn_type === 'virtual') {
    // if this is a virtual camera, use the first camera stream source as the preview source.
    // for virtual cameras this is the most recent uploaded file (uri_stream) by the SMTP gateway.
    // this is deliberately not testing for `&& (pages?.data?.length ?? 0 > 0)` in the `if` below to set
    // the snapshot source to be null for virtual cameras in that case. This triggers an apropriate
    // error in the <VideoSourceSnapshot /> component.

    previewSource = streams?.data[0] as VideoSource | null;
  } else if (camera?.conn_type === 'local') {
    // If it's a local camera, use the camera itself as preview source since it won't have any streams.
    previewSource = camera;
  } else {
    // If this is not a virtual camera, use the first h264 stream as the preview stream if available, otherwise use the first stream.
    const h264Streams =
      streams?.data.filter((stream) => stream.isH264Stream) || [];
    previewSource =
      (h264Streams[0] as VideoSource) ||
      (streams?.data[0] as VideoSource) ||
      null;
  }

  if (isStreamsLoading || isCameraLoading) {
    return (
      <div className={c('loader')}>
        <Loader size="small" text="Loading..." />
      </div>
    );
  }

  if (!camera) {
    return (
      <NotFound
        entity="camera"
        returnTo={`/applications/${applicationID}/deploy/cameras`}
      />
    );
  }

  function handleRename(name: string) {
    if (!camera) {
      return;
    }

    updateCamera(camera.withName(name));
  }

  if (!cameraID) {
    return null;
  }

  return (
    <DetailView
      key={camera.id} // Key is required to correctly re-initialize detail view
      size="default"
    >
      <DetailViewHeader
        entity={camera}
        entityType="cameras"
        singleQueryKey={['camera']}
        isRenaming={isRenaming}
        onRename={hasAccess('deploy_edit') ? handleRename : undefined}
      >
        <CameraDetailEntities camera={camera} />
        <DetailViewStatus entity={camera} onRename={toggleIsRenaming}>
          <CameraDetailStatus camera={camera} />
        </DetailViewStatus>
      </DetailViewHeader>

      <Tabs defaultValue="preview" className={c('tabs')}>
        <TabsList className={c('tabs-list')}>
          <TabsButton value="preview">Preview</TabsButton>
          {camera.isIP() && (
            <>
              <TabsButton value="streams">Streams</TabsButton>
              <TabsButton value="shared-streams">Shared streams</TabsButton>
            </>
          )}
          <TabsButton value="deployments">Deployments</TabsButton>
          <TabsButton value="files">Universal Bridge</TabsButton>
          {hasAccess('deploy_edit') && (
            <TabsButton value="settings">Settings</TabsButton>
          )}
        </TabsList>

        <TabsContent className={c('tab-content')} value="preview">
          <div className={c('preview')}>
            <VideoSourceSnapshot source={previewSource} camera={camera} />
          </div>
        </TabsContent>

        {camera.isIP() && (
          <>
            <TabsContent className={c('tab-content')} value="streams">
              <CameraDetailStreams camera={camera} />
            </TabsContent>

            <TabsContent className={c('tab-content')} value="shared-streams">
              <SharedStreamsList params={{ camera_ids: [camera.id] }} />
            </TabsContent>
          </>
        )}

        <TabsContent className={c('tab-content')} value="files">
          <FileList cameraID={cameraID} />
        </TabsContent>

        <TabsContent value="deployments">
          <DeploymentListSmall streamIds={streamIds} cameraId={cameraID} />
        </TabsContent>

        {hasAccess('deploy_edit') && (
          <TabsContent className={c('tab-content')} value="settings">
            <CameraDetailSettings camera={camera} />
          </TabsContent>
        )}
      </Tabs>
    </DetailView>
  );
}
