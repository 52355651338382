import React from 'react';
import classnames from 'classnames/bind';
import * as RadixPortal from '@radix-ui/react-portal';
import {
  CellProps,
  Column,
  Row,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { Pagination } from '@mantine/core';
import { UseQueryResult } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Deployment from 'types/deployment';
import { PaginatedAPIResponse } from 'types/api';

import {
  useDeleteDeployments,
  useUpdateDeploymentsState,
} from 'hooks/api/useDeployments';
import { useDeploymentsGateways } from 'deployments/hooks/useDeploymentsGateways';
import { useDeploymentsPipelines } from 'deployments/hooks/useDeploymentsPipelines';
import { useHasAccess } from 'hooks/useHasAccess';
import { useMqttDeploymentsState } from 'deployments/hooks/useMqttDeploymentsState';

import { deselectReducer } from 'components/Table/service/deselect';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Loader } from 'components/Loader/Loader';
import { Separator } from 'components/Separator/Separator';
import { TableManager } from 'components/Table/Table';
import { Text } from 'components/Text/Text';
import {
  TableBulkActions,
  TableBulkActionType,
} from 'components/Table/components/BulkActions/BulkActions';

import { BulkOverrideDeploymentsForm } from '../Form/BulkOverrideForm/BulkOverrideForm';
import { DeploymentListItem } from './Item/Item';
import { DeploymentListItemCompact } from './ItemCompact/ItemCompact';
import styles from './List.module.scss';

const c = classnames.bind(styles);

function getRowId(
  row: Deployment,
  relativeIndex: number,
  parent?: Row<Deployment>
) {
  if (row.id && !parent) {
    return String(row.id);
  }

  return String(relativeIndex);
}

export type DeploymentListProps = {
  queryResult: UseQueryResult<PaginatedAPIResponse<Deployment>, APIError>;
  compact?: boolean;
  portalTarget?: React.RefObject<HTMLElement>;
  page: number | undefined;
  onPageChange: (pageIndex: number) => void;
};

export function DeploymentList({
  queryResult: { data: deployments, isLoading, isFetching, isSuccess, error },
  compact,
  portalTarget,
  page: currentPage = 1,
  onPageChange,
}: DeploymentListProps) {
  const data = React.useMemo(() => deployments?.data ?? [], [deployments]);

  const [selectedDeployments, setSelectedDeployments] = React.useState<
    Deployment[]
  >([]);

  const [hasAccess] = useHasAccess();

  useMqttDeploymentsState(data.map(({ id }) => id));

  const {
    data: gateways,
    isLoading: isLoadingGateways,
    isFetching: isFetchingGateways,
  } = useDeploymentsGateways(data);
  const {
    data: pipelines,
    isLoading: isLoadingPipelines,
    isFetching: isFetchingPipelines,
  } = useDeploymentsPipelines(data);

  const [requestDelete, { isLoading: isDeleting }] = useDeleteDeployments();
  const { start, stop, isStarting, isStopping } = useUpdateDeploymentsState();

  const columns = React.useMemo<Column<Deployment>[]>(
    () => [
      {
        Header: 'Deployment',
        Cell({ row }: CellProps<Deployment>) {
          const gateway = gateways?.data.find(
            ({ id }) => id === row.original.gateway_id
          );
          const pipeline = pipelines?.data.find(
            ({ id }) => id === row.original.pipeline_id
          );

          if (compact) {
            return (
              <DeploymentListItemCompact
                deployment={row.original}
                gateway={gateway}
                pipeline={pipeline}
              />
            );
          }

          return (
            <DeploymentListItem
              deployment={row.original}
              gateway={gateway}
              isLoadingGateways={isLoadingGateways}
              pipeline={pipeline}
              isLoadingPipelines={isLoadingPipelines}
            />
          );
        },
      },
    ],
    [gateways, isLoadingGateways, pipelines, isLoadingPipelines, compact]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,

    dispatch,
    // Toggle visible rows
    toggleAllRowsSelected,
    toggleRowSelected,

    page,
    gotoPage,
    state: { selectedRowIds },
  } = useTable(
    {
      id: 'deployments-table',
      columns,
      data,
      initialState: {
        pageIndex: deployments ? deployments.current_page - 1 : 0,
        pageSize: 50,
      },
      manualPagination: true,
      pageCount: deployments?.total_pages,
      bulkEdit: hasAccess('deploy_edit'),
      getRowId,
      autoResetSelectedRows: false,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
      stateReducer: deselectReducer,
    },
    usePagination,
    useFlexLayout,
    useRowSelect,
    // Custom checkbox column to store pipeline id
    (hooks) => {
      hooks.visibleColumns.push((columns, { instance }) => {
        const { tableId = '', checkboxClassName = '' } = instance;
        return [
          {
            id: 'selection',
            Cell({ row }) {
              return (
                <Checkbox
                  {...row.getToggleRowSelectedProps()}
                  id={`${tableId}-${row.id}`}
                  onChange={(event) =>
                    handleRowSelection(row, event.target.checked)
                  }
                  className={checkboxClassName}
                />
              );
            },
          },
          ...columns,
        ];
      });
    }
  );

  function handleRowSelection(row: Row<Deployment>, isChecked: boolean) {
    toggleRowSelected(row.id);

    if (isChecked) {
      setSelectedDeployments((previous) => [...previous, row.original]);
      return;
    }

    setSelectedDeployments((previous) =>
      previous.filter(({ id }) => id !== row.id)
    );
  }

  function handleSelectAll() {
    toggleAllRowsSelected(true);

    const allRowData = page.map((row) => row.original);
    setSelectedDeployments((previous) => {
      const newDeployments = allRowData.filter(
        ({ id }) => !previous.some((prev) => prev.id === id)
      );
      return [...previous, ...newDeployments];
    });
  }

  const [isBulkOverridingDeployments, setIsBulkOverridingDeployments] =
    React.useState(false);

  const actions = React.useMemo<TableBulkActionType[]>(() => {
    if (!hasAccess('deploy_edit')) {
      return [];
    }

    function isDeleteDisabled(selectedIDs: Deployment['id'][]) {
      return page
        .filter(({ original }) => selectedIDs.includes(original.id))
        .some(
          ({ original }) =>
            original.state !== 'error' && original.state !== 'stopped'
        );
    }

    return [
      {
        action: 'Start',
        icon: 'play',
        loading: isStarting,
        onClick: start,
      },
      {
        action: 'Stop',
        icon: 'stop',
        loading: isStopping,
        onClick: stop,
      },
      {
        action: 'Bulk update',
        icon: 'edit',
        onClick() {
          setIsBulkOverridingDeployments(true);
        },
        disabled() {
          if (selectedDeployments) {
            const uniquePipelineIds = new Set(
              selectedDeployments.map(({ pipeline_id }) => pipeline_id)
            );
            return uniquePipelineIds.size !== 1;
          }

          return true;
        },
        disabledTooltip: selectedDeployments.length
          ? 'Bulk override is only available for deployments from the same pipeline.'
          : undefined,
      },
      {
        action: 'Delete',
        loading: isDeleting,
        intent: 'danger',
        icon: 'delete',
        onClick: requestDelete,
        disabled: isDeleteDisabled,
        disabledTooltip: selectedDeployments.length
          ? 'Please make sure all deployments are stopped before deleting.'
          : undefined,
      },
    ];
  }, [
    hasAccess,
    isDeleting,
    isStarting,
    isStopping,
    page,
    selectedDeployments,
    requestDelete,
    start,
    stop,
  ]);

  if (deployments?.total_elements === 0) {
    return (
      <p className={c('list-empty', 'empty', 'info')}>
        No pipeline deployments matching the filter criteria found.
      </p>
    );
  }

  if (isSuccess && deployments?.total_elements === 0) {
    return <Text>No results found.</Text>;
  }

  if (error || !deployments) {
    return <Text>{error?.message || 'An unexpected error occurred.'}</Text>;
  }

  const hasBulkActions = hasAccess('deploy_edit');

  function handleClearSelection() {
    dispatch({ type: 'deselectAllRows' });
    setSelectedDeployments([]);
  }

  const tableManager = (
    <TableManager className={c('bulk-manager', { compact })}>
      {deployments && (
        <Pagination
          className={c('pagination')}
          value={currentPage}
          total={deployments.total_pages}
          onChange={(page) => {
            onPageChange(page);
            gotoPage(page);
          }}
        />
      )}

      <Separator orientation="vertical" />

      {hasBulkActions && (
        <TableBulkActions
          label="deployments"
          selectedRowIds={selectedRowIds}
          totalRows={deployments.total_elements}
          actions={actions}
          onSelectAll={handleSelectAll}
          onClearSelection={handleClearSelection}
        />
      )}
    </TableManager>
  );

  return (
    <div className={c('wrap')}>
      {(isLoading ||
        isLoadingGateways ||
        isLoadingPipelines ||
        isFetching ||
        isFetchingGateways ||
        isFetchingPipelines) && (
        <div className={c('loader')}>
          <Loader size="xsmall" text="Loading..." />
        </div>
      )}

      <div {...getTableProps()}>
        <ul className={c('list')} {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();
            return (
              <li {...rowProps} className={c('deployment')} key={key}>
                {row.cells.map((cell, index) => (
                  <React.Fragment key={index}>
                    {cell.render('Cell')}
                  </React.Fragment>
                ))}
              </li>
            );
          })}
        </ul>
      </div>

      {hasBulkActions &&
        (portalTarget?.current ? (
          <RadixPortal.Root container={portalTarget.current}>
            {tableManager}
          </RadixPortal.Root>
        ) : (
          tableManager
        ))}

      {selectedDeployments && selectedDeployments.length > 0 && (
        <BulkOverrideDeploymentsForm
          open={isBulkOverridingDeployments}
          pipelineId={selectedDeployments[0].pipeline_id}
          deploymentIds={selectedRowIds}
          onCancel={() => setIsBulkOverridingDeployments(false)}
          onSuccess={() => setIsBulkOverridingDeployments(false)}
        />
      )}
    </div>
  );
}
