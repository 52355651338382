import React from 'react';
import classnames from 'classnames/bind';
import { useLocation, useParams } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import LumeoEvent from 'types/event';

import { useDeployment } from 'deployments/hooks/useDeployment';
import { useEvents } from 'hooks/api/useEvents';
import { useHasAccess } from 'hooks/useHasAccess';
import { useRenameDeployment } from 'hooks/api/useDeployment';

import { NotFound } from 'views/NotFound/NotFound';
import { ApplicationParams } from 'application/views/App/AppContainer';

import { DeploymentEntities } from 'deployments/components/Entities/Entities';
import { DeploymentFiles } from 'deployments/components/DeploymentFiles/DeploymentFiles';
import { DeploymentLogs } from 'deployments/components/Logs/Logs';
import { DeploymentStatus } from 'deployments/components/Status/Status';
import {
  DetailView,
  DetailViewHeader,
  DetailViewStatus,
} from 'components/DetailView/DetailView';
import { EditDeploymentForm } from 'deployments/components/Form';
import { EventsList } from 'events/components/List/List';
import { Loader } from 'components/Loader/Loader';
import { ReactComponent as EmptyIllustration } from 'deployments/images/empty-pipeline-deployments.svg';
import { SharedStreamsList } from 'streams/components/SharedStreams/SharedStreamsList';
import { StreamsList } from 'streams/components/List/List';
import { Tabs, TabsButton, TabsContent, TabsList } from 'components/Tabs/Tabs';
import { Text } from 'components/Text/Text';

import styles from './DeploymentDetail.module.scss';

const c = classnames.bind(styles);

const EMPTY_EVENTS: LumeoEvent[] = [];

export type DeploymentParams = ApplicationParams & {
  deploymentID: string;
};

export function DeploymentDetail() {
  const [hasAccess] = useHasAccess();

  const rootRef = React.useRef<HTMLDivElement>(null);
  const { state: locationState } = useLocation();

  const { deploymentID } = useParams<DeploymentParams>();
  const {
    data: deployment,
    isPlaceholderData,
    isLoading,
  } = useDeployment(deploymentID, { enabled: Boolean(deploymentID) });

  const { mutate: renameDeployment } = useRenameDeployment();

  const [tab, setTab] = React.useState<string>(
    locationState?.defaultTab ?? 'streams'
  );

  const [isRenaming, toggleIsRenaming] = useToggle();

  const { data: events, refetch } = useEvents(
    {
      object_ids: deploymentID ? [deploymentID] : [],
      categories: ['deployment'],
    },
    {
      enabled:
        Boolean(deploymentID) && deployment?.isActive && tab === 'events',
      refetchInterval: 10000,
    }
  );

  React.useEffect(() => {
    if (!deployment || (deployment && deployment.state !== 'error')) {
      return;
    }

    // Refetch events when error occurs
    refetch();
  }, [deployment, refetch]);

  if (!deploymentID) {
    return null;
  }

  if (isLoading && !isPlaceholderData) {
    return <Loader text="Loading deployment..." />;
  }

  if (!deployment) {
    return <NotFound entity="pipeline deployment" image={EmptyIllustration} />;
  }

  function handleTabChange(nextTab: string) {
    setTab(nextTab);
  }

  function handleRename(name: string) {
    if (!deploymentID) {
      return;
    }

    renameDeployment({ id: deploymentID, name });
  }

  const { state } = deployment;

  return (
    <DetailView
      key={deployment.id} // Key is required to correctly re-initialize detail view
      size="default"
      className={c('wrap')}
      ref={rootRef}
    >
      <DetailViewHeader
        entity={deployment}
        isRenaming={isRenaming}
        onRename={handleRename}
      >
        <DeploymentEntities deployment={deployment} />

        <DetailViewStatus entity={deployment} onRename={toggleIsRenaming}>
          <DeploymentStatus deployment={deployment} />
        </DetailViewStatus>

        {deployment?.state_details && (
          <Text
            className={c('status-details')}
            intent={
              state === 'error' || state === 'interrupted'
                ? 'danger'
                : undefined
            }
          >
            {deployment.state_details}
            {state === 'deploying' && <>&hellip;</>}
          </Text>
        )}
      </DetailViewHeader>

      <Tabs value={tab} onValueChange={handleTabChange} className={c('tab')}>
        <TabsList
          className={c('tabs-list')}
          aria-label="Manage this deployment"
        >
          <TabsButton value="streams">Output streams</TabsButton>
          <TabsButton value="shared-streams">Shared streams</TabsButton>
          <TabsButton value="files">Recordings</TabsButton>
          {hasAccess('deploy_edit') && (
            <TabsButton value="configuration">Configuration</TabsButton>
          )}
          <TabsButton value="logs">Logs</TabsButton>
          <TabsButton value="events">Events</TabsButton>
        </TabsList>
        <TabsContent className={c('tab-content')} value="streams">
          <StreamsList />
        </TabsContent>
        <TabsContent className={c('tab-content')} value="shared-streams">
          <SharedStreamsList params={{ deployment_ids: [deployment.id] }} />
        </TabsContent>
        <TabsContent className={c('tab-content')} value="files">
          <DeploymentFiles state={deployment.state} />
        </TabsContent>
        {hasAccess('deploy_edit') && (
          <TabsContent className={c('tab-content')} value="configuration">
            <EditDeploymentForm
              className={c('configuration')}
              deployment={deployment}
            />
          </TabsContent>
        )}
        <TabsContent className={c('tab-content', 'logs-tab')} value="logs">
          <DeploymentLogs deployment={deployment} />
        </TabsContent>

        <TabsContent className={c('tab-content')} value="events">
          <EventsList events={events || EMPTY_EVENTS} deployment={deployment} />
        </TabsContent>
      </Tabs>
    </DetailView>
  );
}
