import React from 'react';
import classnames from 'classnames/bind';
import { Skeleton } from '@mantine/core';

import Deployment from 'types/deployment';
import LumeoEvent from 'types/event';

import { Code } from 'components/Code/Code';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { Text } from 'components/Text/Text';

import { EventEntity } from '../EventEntity/EventEntity';
import { EventsTableDateCell } from '../Table/DateCell';
import { EventsTableSeverityCell } from '../Table/SeverityCell';
import styles from './List.module.scss';

const c = classnames.bind(styles);

export type EventsListProps = {
  events: LumeoEvent[];
  deployment?: Deployment;
  isLoading?: boolean;
  hideEntity?: boolean;
};

export function EventsList({
  events,
  deployment,
  hideEntity,
  isLoading,
}: EventsListProps) {
  if (isLoading) {
    return (
      <div className={c('events-list')}>
        <SkeletonList
          component={
            <div className={c('events-item')}>
              <div className={c('event')}>
                <div className={c('event-time')}>
                  <Skeleton>
                    <span>Sep 24, 2024</span>
                    <br />
                    <span>13:05:37 AM</span>
                  </Skeleton>
                </div>

                <div className={c('event-severity')}>
                  <Skeleton width={16} height={16} radius={16} />
                </div>

                <div className={c('event-payload')}>
                  <Skeleton width="25%">
                    <Text>Payload</Text>
                  </Skeleton>
                </div>
                <div className={c('event-entity')}>
                  <Skeleton width="65%">
                    <Text>Entity</Text>
                  </Skeleton>
                </div>

                <div className={c('event-type')}>
                  <Skeleton>
                    <Code size="small">GATEWAY.ONLINE</Code>
                  </Skeleton>
                </div>
              </div>
            </div>
          }
          min={14}
          max={18}
        />
      </div>
    );
  }

  if (!events.length) {
    return <div className={c('events-empty')}>No events recorded yet.</div>;
  }

  return (
    <ol className={c('events-list')}>
      {events.map((event) => (
        <li className={c('events-item')} key={event.id}>
          <div className={c('event')}>
            <div className={c('event-time')}>
              <EventsTableDateCell event={event} />
            </div>
            <div className={c('event-severity')}>
              <EventsTableSeverityCell event={event} />
            </div>
            <Text asChild>
              <strong className={c('event-payload')}>{event.payload}</strong>
            </Text>
            {!hideEntity && (
              <EventEntity
                event={event}
                deployment={deployment}
                className={c('event-entity')}
              />
            )}
            <Code className={c('event-type')} size="small">
              {event.event_type}
            </Code>
          </div>
        </li>
      ))}
    </ol>
  );
}
