import axios from 'axios';

import { API_BASE_URL } from 'services/api';
import errorResponseInterceptor from 'services/api_error_handler';

export const API_AUTH = axios.create({
  baseURL: API_BASE_URL,
  responseType: 'json',
});

API_AUTH.interceptors.response.use(
  (response) => response.data,
  (error) => errorResponseInterceptor(error)
);

export type AuthenticationRedirectType = 'password' | 'saml_sso_redirect';

export type AuthenticationRedirect = {
  type: AuthenticationRedirectType;
  identity_provider?: string;
};

export async function postAuthAPI(
  url: string,
  body?: any,
  { token, ...options }: Partial<RequestInit> & { token?: string } = {}
) {
  const headers: HeadersInit = {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const requestOptions: RequestInit = {
    headers,
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    ...options,
  };

  if (body) {
    requestOptions.body = JSON.stringify(body);
  }

  return fetch(API_BASE_URL + url, requestOptions).then(async (res) => {
    if (!res.ok) {
      return Promise.reject(await res.json());
    }

    return res;
  });
}

export async function getAuthAPI(
  url: string,
  { token, ...options }: Partial<RequestInit> & { token?: string } = {}
) {
  const headers: HeadersInit = {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const requestOptions: RequestInit = {
    headers,
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    ...options,
  };

  return fetch(API_BASE_URL + url, requestOptions).then(async (res) => {
    if (!res.ok) {
      return Promise.reject(await res.json());
    }

    return res;
  });
}

export async function postSelfServeSignup(url: string, body?: any) {
  const headers: HeadersInit = {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
  };

  const requestOptions: RequestInit = {
    headers,
    method: 'POST',
    credentials: 'omit',
    mode: 'cors',
  };

  if (body) {
    requestOptions.body = JSON.stringify(body);
  }

  return fetch(API_BASE_URL + url, requestOptions).then(async (res) => {
    if (!res.ok) {
      return Promise.reject(await res.json());
    }

    return res;
  });
}

export async function getAuthenticationRedirect(email: string) {
  return await axios
    .get<AuthenticationRedirect>(`${API_BASE_URL}/internal/auth/check_email`, {
      params: { email },
    })
    .then((res) => res.data);
}
