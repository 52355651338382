import React from 'react';
import classNames from 'classnames/bind';
import { useQueryClient } from '@tanstack/react-query';

import styles from './InstanceBanner.module.scss';

const c = classNames.bind(styles);

const CONSOLE_VERSION = process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA;

function Wrap({
  children,
  instance,
}: React.PropsWithChildren<{ instance: string }>) {
  React.useEffect(() => {
    document.documentElement.style.setProperty('--offset-y', '2rem');

    return () => {
      document.documentElement.style.removeProperty('--offset-y');
    };
  }, []);

  return (
    <div id="instance-banner" className={c('banner', instance)}>
      {children}
    </div>
  );
}

/**
 * Render a banner on top of the app to identify the current server instance.
 */
export function InstanceBanner() {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.setQueryData(['console-version'], CONSOLE_VERSION);
  }, [queryClient]);

  if (!process.env.REACT_APP_BASE_URL || !process.env.REACT_APP_API_URL) {
    return null;
  }

  if (process.env.REACT_APP_BASE_URL.includes('localhost')) {
    if (process.env.REACT_APP_API_URL.includes('api-dev.')) {
      return <Wrap instance="dev">Local + Dev API</Wrap>;
    }

    if (process.env.REACT_APP_API_URL.includes('api-staging.')) {
      return <Wrap instance="staging">Local + Staging API</Wrap>;
    }

    if (process.env.REACT_APP_API_URL.includes('api.')) {
      return <Wrap instance="prod">Local + Prod API</Wrap>;
    }
  }

  if (process.env.REACT_APP_API_URL.includes('api-dev.')) {
    return <Wrap instance="dev">Development</Wrap>;
  }

  if (process.env.REACT_APP_API_URL.includes('api-staging.')) {
    return <Wrap instance="staging">Staging</Wrap>;
  }

  return null;
}
