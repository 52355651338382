import React from 'react';
import c from 'classnames';

import { Icon, IconType } from 'components/Icon/Icon';

export type InlineNotificationProps = React.PropsWithChildren<{
  intent?: 'success' | 'danger' | 'info';
  icon?: IconType;
  size?: 'small';
}>;

export function InlineNotification({
  intent,
  icon,
  size,
  children,
}: InlineNotificationProps) {
  if (!icon) {
    switch (intent) {
      case 'success':
        icon = 'check-circle';
        break;

      case 'danger':
        icon = 'error';
        break;
    }
  }

  return (
    <p
      className={c('inline-notification', icon, size, {
        [`theme ${intent}`]: intent,
      })}
    >
      {icon && <Icon name={icon} className={c('inline-notification-icon')} />}
      <span>{children}</span>
    </p>
  );
}
