import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import LumeoEvent, { LumeoEventObject, LumeoEventSeverity } from 'types/event';

import { useAPI } from './useAPI';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';

export type UseEventsFilterParams = {
  categories?: LumeoEventObject[];
  event_types?: string[];
  severities?: LumeoEventSeverity[];
  /** ISO 8601 string */
  since?: string;
  /** ISO 8601 string */
  until?: string;
  object_ids?: string[];
  object_types?: LumeoEventObject[];
};

export function useEvents(
  params: UseEventsFilterParams = {},
  options: UseQueryOptions<LumeoEvent[], APIError> = {}
) {
  const api = useAPI();

  return useAuthenticatedQuery(
    ['events', api.applicationID, params],
    () => api.events.list(params),
    options
  );
}
