import classnames from 'classnames/bind';

import AIModel from 'types/ai_model';

import { relativeTimeFromNow } from 'services/date';
import { useAppRedirect } from 'hooks/useAppRedirect';
import { useIsLumeoAdmin } from 'hooks/useAuth';

import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { Text } from 'components/Text/Text';

import styles from './ModelCard.module.scss';

const c = classnames.bind(styles);

type ModelCardProps = {
  model: AIModel;
};

export function ModelCard({ model }: ModelCardProps) {
  const redirect = useAppRedirect();
  const isAdmin = useIsLumeoAdmin();
  const canEdit = !model.isMarketplaceModel() || isAdmin;
  const isUserModel = !model.isMarketplaceModel();

  function handleEdit() {
    if (model.isMarketplaceModel() && isAdmin) {
      redirect(`/admin/marketplace-models/${model.id}`);
    } else {
      redirect(`/design/models/${model.id}`);
    }
  }

  return (
    <div className={c('model-card')}>
      <div className={c('content')}>
        {model.gallery_img_url && (
          <div className={c('image')}>
            <img src={model.gallery_img_url} alt="" />
          </div>
        )}

        <Heading className={c('name')} level="4">
          {model.name}
        </Heading>

        {canEdit && (
          <IconButton
            className={c('edit-button')}
            icon="edit"
            onClick={handleEdit}
            label={`Edit ${model.name}`}
            variant="ghost"
            size="small"
          />
        )}

        <Text className={c('description', { user: isUserModel })}>
          {model.description}
        </Text>

        <div className={c('footer')}>
          <Text>
            {model.architecture} / {model.capability}
          </Text>
          {canEdit && <Text>{relativeTimeFromNow(model.updated_at)}</Text>}
        </div>
      </div>
    </div>
  );
}
