import React from 'react';
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import LumeoEvent, { LumeoEventObject, LumeoEventSeverity } from 'types/event';

import { APIObject } from 'services/api';
import { AppEntityObject } from 'hooks/useEntities';
import { lastElement } from 'services/array';
import { useAPI } from 'hooks/api/useAPI';

import { useAuthenticatedInfiniteQuery } from './useAuthenticatedQuery';

export const EVENTS_LIMIT = 50;

export type UseEventsParams = {
  categories?: LumeoEventObject[];
  event_types?: string[];
  severities?: LumeoEventSeverity[];
  since?: number;
  until?: number;
  object_ids?: string[];
  object_types?: LumeoEventObject[];
};

export type UseEventsResult = Omit<UseInfiniteQueryResult, 'data'> & {
  count: number;
  isCompleted: boolean;
  data: LumeoEvent[];
};

function getUntil(a?: number, b?: string) {
  let date: Date = new Date();

  if (!a && !b) {
    return undefined;
  }

  if (a && b) {
    date = new Date(Math.min(a, +new Date(b)));
  }
  if (a && !b) {
    date = new Date(a);
  }
  if (!a && b) {
    date = new Date(b);
  }

  return date.toISOString();
}

type InfiniteEventsEntities = Partial<Record<LumeoEventObject, string[]>>;

/**
 * @deprecated
 */
export function useInfiniteEvents(
  { since, until, categories }: UseEventsParams = {},
  options: UseInfiniteQueryOptions<LumeoEvent[], APIError> = {}
): UseEventsResult {
  const api = useAPI();

  async function listEvents({ pageParam = undefined }) {
    const events = await api.events.list({
      until: until ? getUntil(until, pageParam) : undefined,
      since: since ? new Date(since).toISOString() : undefined,
      categories,
    });

    // Collect all object ids
    const entityIds = events.reduce<InfiniteEventsEntities>(
      (entities, { object_type, object_id }) => {
        if (!object_type || !object_id) {
          return entities;
        }

        if (Array.isArray(entities[object_type])) {
          entities[object_type]!.push(object_id);
        } else {
          entities[object_type] = [object_id];
        }

        return entities;
      },
      {}
    );

    const entityObjects: Partial<Record<LumeoEventObject, AppEntityObject[]>> =
      {};

    // Request related entities
    await Promise.all(
      Object.entries(entityIds).map(async ([entity, ids]) => {
        const accessor = `${entity}s` as keyof APIObject;
        const fn =
          (api[accessor] as any)?.listPaginated ?? (api[accessor] as any)?.list;

        if (!fn) {
          return;
        }

        const uniqueIds = [...new Set(ids)];

        const response = await fn({
          pagination: 'offset',
          limit: uniqueIds.length,
          page: 1,
          [`${entity}_ids`]: uniqueIds,
        });

        if (!response || !Object.keys(response).length) {
          return;
        }

        entityObjects[entity as LumeoEventObject] = response.data;
      }, {} as Partial<Record<LumeoEventObject, AppEntityObject[]>>)
    );

    // Inject objects into event
    return events.map((event) => {
      if (
        !event.object_type ||
        !event.object_id ||
        !(event.object_type in entityObjects)
      ) {
        return event;
      }

      event.object = entityObjects[event.object_type]!.find(
        ({ id }) => id === event.object_id
      );
      return event;
    });
  }

  const queryResult = useAuthenticatedInfiniteQuery(
    ['events', api.applicationID, { since, until, categories }],
    listEvents,
    {
      ...options,
      getNextPageParam: (lastPage) => lastElement(lastPage)?.event_ts,
      refetchInterval: 10000,
    }
  );

  const count = React.useMemo(
    () => queryResult.data?.pages.flat().length || 0,
    [queryResult.data]
  );

  const isCompleted = React.useMemo(() => {
    if (queryResult.status === 'loading') {
      return false;
    }

    if (queryResult.hasNextPage !== undefined) {
      return !queryResult.hasNextPage;
    }

    return count < EVENTS_LIMIT;
  }, [queryResult.status, queryResult.hasNextPage, count]);

  const data = React.useMemo<LumeoEvent[]>(() => {
    if (queryResult.data) {
      return queryResult.data.pages.flat();
    }
    return [];
  }, [queryResult.data]);

  return { ...queryResult, count, isCompleted, data };
}
