/** @deprecated
 *  Use {@link luxon} unit instead.
 */
export enum TimeUnit {
  WEEK = 'week',
  WEEKS = 'weeks',
  DAY = 'day',
  DAYS = 'days',
  HOUR = 'hour',
  HOURS = 'hours',
  MINUTE = 'minute',
  MINUTES = 'minutes',
  SECOND = 'second',
  SECONDS = 'seconds',
}

/** @deprecated
 * Use {@link luxon} instead.
 */
export function subtractFromDate(
  date: Date,
  count: number,
  unit: TimeUnit
): Date {
  let milliseconds = 1;
  date = new Date(date);

  switch (unit) {
    case TimeUnit.WEEK:
    case TimeUnit.WEEKS:
      milliseconds *= 7;
    // falls through
    case TimeUnit.DAY:
    case TimeUnit.DAYS:
      milliseconds *= 24;
    // falls through
    case TimeUnit.HOUR:
    case TimeUnit.HOURS:
      milliseconds *= 60;
    // falls through
    case TimeUnit.MINUTE:
    case TimeUnit.MINUTES:
      milliseconds *= 60;
    // falls through
    case TimeUnit.SECOND:
    case TimeUnit.SECONDS:
      milliseconds *= 1000;
      break;
    default:
      throw new Error(`Unknown time unit \`${unit}\`.`);
  }

  if (unit === 'week') {
    date.setHours(0, 0, 0, 0);
  }

  const timestamp = typeof date === 'number' ? date : date.valueOf();

  return new Date(timestamp - milliseconds * count);
}

const rtf = new (Intl as any).RelativeTimeFormat('en-US', { numeric: 'auto' });

export function relativeTimeFromNow(date?: string | number | Date): string {
  const now = Date.now();
  const timestamp = date ? +new Date(date) : now;
  const elapsed = now - timestamp;

  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  if (elapsed < msPerMinute) {
    return rtf.format(0, 'seconds');
  } else if (elapsed < msPerHour) {
    return rtf.format(-Math.floor(elapsed / msPerMinute), 'minutes');
  } else if (elapsed < msPerDay) {
    return rtf.format(-Math.floor(elapsed / msPerHour), 'hours');
  } else if (elapsed < msPerMonth) {
    return rtf.format(-Math.floor(elapsed / msPerDay), 'days');
  } else if (elapsed < msPerYear) {
    return rtf.format(-Math.floor(elapsed / msPerMonth), 'months');
  }

  return new Date(timestamp).toLocaleDateString('en-US');
}

/** @deprecated
 * Use {@link luxon} instead.
 */
export function isToday(date: string | number | Date): boolean {
  const now = new Date();
  const dateInput = new Date(date);

  if (
    now.getDate() === dateInput.getDate() &&
    now.getMonth() === dateInput.getMonth() &&
    now.getFullYear() === dateInput.getFullYear()
  ) {
    return true;
  }

  return false;
}
