import classNames from 'classnames/bind';

import { pluralize } from 'services/string';

import { Button } from 'components/Button/Button';
import { ButtonGroup, Group, Pagination, Pill } from '@mantine/core';
import { Icon } from 'components/Icon/Icon';
import { TableManager } from 'components/Table/Table';

import { useFilesSearchState } from './search_context';
import styles from './Search.module.scss';

const c = classNames.bind(styles);

export type FilesBulkManagerProps = {
  total: number;
  page?: number;
  onPageChange: (page: number) => void;
};

export function FilesBulkManager({
  total,
  page = 1,
  onPageChange,
}: FilesBulkManagerProps) {
  const { selectedFiles, isDeleting, dispatch, deleteSelectedFiles } =
    useFilesSearchState();

  return (
    <TableManager className={c('bulk-manager')}>
      <Group>
        <Pagination total={total} value={page} onChange={onPageChange} />

        {/* TODO */}
        {/* <Button>Select all on this page</Button> */}

        {selectedFiles.length > 0 && (
          <>
            <Button size="small" onClick={() => dispatch({ type: 'clear' })}>
              Clear
            </Button>

            <Group gap="xs">
              <Pill>{selectedFiles.length}</Pill>{' '}
              {pluralize(selectedFiles.length, 'file')} selected
            </Group>
          </>
        )}
      </Group>

      <ButtonGroup>
        <Button
          loading={isDeleting}
          onClick={deleteSelectedFiles}
          intent="danger"
          size="small"
          disabled={selectedFiles.length === 0}
        >
          <Icon name="delete" />
          <span>Delete</span>
        </Button>
      </ButtonGroup>
    </TableManager>
  );
}
