import React from 'react';

import LumeoEvent from 'types/event';
import { Filter, TimeFilterOption } from 'types/filters';

import { Filters } from './Filters';
import FilterContext, { useFilterState } from './FilterContext';

type FilterViewProps = React.PropsWithChildren<{
  data: any[];
  entity: string;
  filters?: Filter[];
  disableSearch?: boolean;
  timeFilter?: boolean;
  searchKeys?: string[];
  onTimeFilterChange?: (
    filter: TimeFilterOption | undefined,
    from: number,
    until: number
  ) => void;
}>;

const statusFilter = {
  key: 'status',
  label: 'Status',
  options: [
    { label: 'All', value: '' },
    { label: 'Online', value: 'online' },
    { label: 'Offline', value: 'offline' },
  ],
};

/** @deprecated Use QuerySearch instead. */
export function FilterView({
  data = [],
  entity,
  filters = [],
  timeFilter: timeFilterEnabled,
  onTimeFilterChange,
  children,
  disableSearch,
  searchKeys = ['name', 'id'],
}: FilterViewProps) {
  const [filteredData, setFilteredData] = React.useState(data);

  // timeFilter range needs to be saved as ref to
  // always use the current value in the predicateFn filter function
  const fromRef = React.useRef<Date>();
  const untilRef = React.useRef<Date>(new Date());
  const [isRangePickerOpen, setIsRangePickerOpen] = React.useState(false);

  const timeFilters = React.useMemo<Filter>(
    () => ({
      key: 'time',
      label: 'Time',
      options: [
        { label: 'Last 1 Hour', value: '1-hour' },
        { label: 'Last 24 Hours', value: '24-hours' },
        { label: 'Last Week', value: '1-week' },
        {
          label: 'Custom',
          value: 'custom',
          onClick: () => setIsRangePickerOpen(true),
        },
      ],
      predicateFn: ({ event_ts }: LumeoEvent) => {
        const date = new Date(event_ts);
        const isWithinUpperBound = date <= untilRef.current;
        const isWithinLowerBound = !fromRef.current || date >= fromRef.current;
        return isWithinLowerBound && isWithinUpperBound;
      },
    }),
    [setIsRangePickerOpen]
  );

  const handleTimeFilterChange = (
    filter: TimeFilterOption | undefined,
    from: number,
    until: number
  ) => {
    fromRef.current = new Date(from);
    untilRef.current = new Date(until);
    onTimeFilterChange && onTimeFilterChange(filter, from, until);
  };

  const context = {
    data,
    filteredData,
    setFilteredData,
    entity,
    filters,
    allFilters: timeFilterEnabled ? [timeFilters, ...filters] : filters,
    searchKeys,
    isRangePickerOpen,
    setIsRangePickerOpen,
  };

  return (
    <FilterContext.Provider value={context}>
      <Filters
        disableSearch={disableSearch}
        timeFilters={timeFilterEnabled && timeFilters}
        onTimeFilterChange={
          timeFilterEnabled ? handleTimeFilterChange : undefined
        }
      />
      {children}
    </FilterContext.Provider>
  );
}

export default FilterView;
export { FilterContext, useFilterState, statusFilter };
