import { useCallback, useState } from 'react';
import qs from 'query-string';

// https://medium.com/swlh/using-react-hooks-to-sync-your-component-state-with-the-url-query-string-81ccdfcb174f
export function setQueryStringWithoutPageReload(qsValue: string | undefined) {
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${qsValue}`;

  window.history.pushState({ path: newurl }, '', newurl);
}

export function setQueryStringValue(key: string, value: string | undefined) {
  let values = qs.parse(window.location.search);

  if (value) {
    values = { ...values, [key]: value };
  } else {
    delete values[key];
  }

  const str = qs.stringify(values);

  setQueryStringWithoutPageReload(str ? `?${str}` : '');
}

function getQueryStringValue(key: string): string {
  return qs.parse(window.location.search)[key] as string;
}

/**
 * Extension of `React.useState` with two-way binding to the URL query params.
 */
export function useQueryStringState(key: string, initialValue?: string) {
  const [value, setValue] = useState<string>(
    getQueryStringValue(key) || initialValue || ''
  );

  const onSetValue = useCallback(
    (newValue: any) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue] as const;
}
