import React from 'react';
import { FormProvider, useFormState } from 'react-hook-form';

import Pipeline from 'types/pipeline';
import { type DeploymentConfiguration } from 'deployments/types/DeploymentForm';

import { ActivePipelineContext } from 'pipelines/context/active_pipeline';
import { type VideoSource } from 'hooks/api/useVideoSources';

import { DeploymentFormContent } from 'deployments/components/Form/Content';
import { useDeploymentFormContent } from 'deployments/components/Form/useDeploymentFormContent';

type RapidDeployConfigurationFormContentProps = {
  videoSource: VideoSource;
  pipeline: Pipeline;
  onSubmit: (configuration: DeploymentConfiguration) => void;
  onIsValidChange: (isValid: boolean) => void;
};

function RapidDeployConfigurationFormContent(
  {
    videoSource,
    pipeline,
    onSubmit,
    onIsValidChange,
  }: RapidDeployConfigurationFormContentProps,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  const formMethods = useDeploymentFormContent(
    undefined,
    videoSource,
    pipeline
  );

  const { isValid } = useFormState({ control: formMethods.control });

  React.useEffect(() => {
    onIsValidChange(isValid);
  }, [isValid, onIsValidChange]);

  return (
    <ActivePipelineContext.Provider value={pipeline}>
      <FormProvider {...formMethods}>
        <DeploymentFormContent onSubmit={onSubmit} ref={ref} />
      </FormProvider>
    </ActivePipelineContext.Provider>
  );
}

const ForwardRef = React.forwardRef<
  HTMLFormElement,
  RapidDeployConfigurationFormContentProps
>(RapidDeployConfigurationFormContent);

export { ForwardRef as RapidDeployConfigurationFormContent };
