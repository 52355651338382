import { AxiosInstance } from 'axios';

import AIModel, { ModelFiles, UpdateModelParams } from 'types/ai_model';
import AccessToken from 'types/token';
import Account from 'types/account';
import Application from 'types/application';
import Camera from 'types/camera';
import Category from 'types/category';
import Component from 'pipelines/services/rete/nodes/component';
import Dashboard from 'types/dashboard';
import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import LumeoEvent from 'types/event';
import LumeoJWT from 'types/jwt';
import Organization from 'types/organization';
import OrganizationInvitation from 'types/invitation';
import OrganizationUsageLimits from 'types/organization_usage_limits';
import OrganizationUsageStatistics from 'types/organization_usage_statistics';
import Pipeline from 'types/pipeline';
import PipelineTemplate from 'types/pipeline_template';
import PricingPlan from 'types/pricing_plan';
import PropelToken from 'types/propel_token';
import Stream from 'types/stream';
import { Tag } from 'types/tag';
import {
  AcceptAndCreateAccountVariables,
  InvitationVariables,
} from 'hooks/api/useInvitations';
import { AppEntityObject, TaggableEntityObject } from 'hooks/useEntities';
import { ApplicationRole, OrganizationRole } from 'types/account_role';
import { CreateAccessTokenVariables } from 'hooks/api/useAccessTokens';
import { CreateOrganizationInvitationVariables } from 'settings/hooks/useCreateOrganizationInvitation';
import { CreatePipelineVariables } from 'pipelines/hooks/useCreatePipeline';
import { DiscoveryRequestResponse } from 'hooks/api/useGatewayCameras';
import { GatewayConfiguration } from 'types/gateway_configuration';
import { GatewaySpecification } from 'types/gateway_specification';
import { JwtType, JwtParams } from 'hooks/useJWT';
import { LumeoFile } from 'types/file';
import { LumeoUploadFile } from 'files/hooks/useUploadFile';
import { OffsetPaginated, OffsetPaginationParams } from 'types/pagination';
import { PaginatedAPIResponse } from 'types/api';
import { UpdatePasswordParams } from 'account/components/ChangePasswordForm/ChangePasswordForm';
import { UpdatePipelineVariables } from 'pipelines/hooks/useUpdatePipeline';
import { UseBillingResponse } from 'hooks/api/useBilling';
import { UseCamerasFilterParams } from 'cameras/hooks/useCameras';
import { UseCategoriesFilterParams } from 'hooks/api/useCategories';
import { UseCreateGatewayConfigurationParams } from 'gateways/hooks/useCreateGatewayConfiguration';
import { UseDashboardsFilterParams } from 'dashboards/hooks/useDashboards';
import { UseDeploymentsFilterParams } from 'hooks/api/useDeployments';
import { UseEventsFilterParams } from 'hooks/api/useEvents';
import { UseGatewaysFilterParams } from 'hooks/api/useGateways';
import { UseMembersFilterParams } from 'settings/hooks/useMembers';
import { UseOrganizationInvitationsParams } from 'settings/hooks/useOrganizationInvitations';
import { UsePipelineTemplatesFilterParams } from 'pipelines/hooks/usePipelineTemplates';
import { UsePipelinesFilterParams } from 'pipelines/hooks/usePipelines';
import { UseServerVersionResponse } from 'hooks/api/useServerVersion';
import { UseSnapshotResponse } from 'hooks/useSnapshot';
import { UseStreamsFilterParams } from 'streams/hooks/useStreams';
import { UseUpdateGatewayConfigurationParams } from 'gateways/hooks/useUpdateGatewayConfiguration';
import {
  VideoSource,
  UseVideoSourcesFilterParams,
} from 'hooks/api/useVideoSources';

export const API_BASE_URL = `${process.env.REACT_APP_API_URL}/v1`;

type CrudAPI<T, TId = string | undefined, TParams = Record<string, any>> = {
  list: (params?: TParams) => Promise<T[]>;
  create: (el: T) => Promise<T>;
  update: (el: T) => Promise<T>;
  delete: (el: TId) => Promise<void>;
};

/** Updated type for paginated endpoints */
type CommonCRUDEndpoints<T extends AppEntityObject, TParams> = {
  list: (
    params: OffsetPaginationParams & TParams
  ) => Promise<PaginatedAPIResponse<T>>;
  read: (el: T['id']) => Promise<T>;
  create: (el: T) => Promise<T>;
  update: (el: T) => Promise<T>;
  delete: (el: T['id']) => Promise<void>;
};

type CRUDEndpointsNotTaggable<
  T extends AppEntityObject,
  TParams,
> = CommonCRUDEndpoints<T, TParams> & {
  addTags?: never;
  removeTags?: never;
};

type CRUDEndpointsTaggable<
  T extends TaggableEntityObject,
  TParams,
> = CommonCRUDEndpoints<T, TParams> & {
  addTags: (el: T['id'], tagIds: Tag['id'][]) => Promise<Tag['id'][]>;
  removeTags: (el: T['id'], tagIds: Tag['id'][]) => Promise<Tag['id'][]>;
};

type CRUDEndpoints<
  T extends AppEntityObject,
  TParams,
> = T extends TaggableEntityObject
  ? CRUDEndpointsTaggable<T, TParams>
  : CRUDEndpointsNotTaggable<T, TParams>;

/** @deprecated Define your fetch function in the queryFn prop of useQuery */
export type APIObject = {
  authToken?: string;
  applicationID?: string;
  setApplicationID: (applicationID: string) => void;
  client: AxiosInstance;

  sendBeacon: (url: string, data?: URLSearchParams) => void;

  /** @deprecated */
  internal: {
    readServerVersion: () => Promise<UseServerVersionResponse>;
  };

  /** @deprecated */
  categories: {
    list: (
      filterParams?: UseCategoriesFilterParams
    ) => Promise<PaginatedAPIResponse<Category>>;
  };

  /** @deprecated */
  organizations: Pick<CrudAPI<Organization>, 'list' | 'update'> & {
    read: (
      organizationID: Organization['id']
    ) => Promise<Organization | undefined>;
    readCurrentPlan: (
      organizationID: Organization['id']
    ) => Promise<PricingPlan | null>;
    readUsageStatistics: (
      organizationID: Organization['id']
    ) => Promise<OrganizationUsageStatistics>;
    readUsageLimits: (
      organizationID: Organization['id']
    ) => Promise<OrganizationUsageLimits | null>;
    updateUsageLimits: (
      organizationID: Organization['id'],
      usageLimits: OrganizationUsageLimits
    ) => Promise<OrganizationUsageLimits | null>;
    switchPlan: (
      organizationID: Organization['id'],
      planID: PricingPlan['id']
    ) => Promise<void>;
    applications: Pick<
      CrudAPI<Application, Application['id'], Organization['id']>,
      'list'
    >;
    billing: {
      /**
       * Generate URL for Stripe customer portal
       */
      createUrl: ({
        organizationID,
        return_url,
      }: {
        organizationID: Organization['id'];
        return_url: string;
      }) => Promise<UseBillingResponse>;
      setupPayment: (organizationID: Organization['id']) => Promise<string>;
      hasValidPaymentMethods: (
        organizationID: Organization['id']
      ) => Promise<boolean>;
    };
    plans: {
      list: (organizationID: Organization['id']) => Promise<PricingPlan[]>;
      listIncludedNodes: (
        organizationID: Organization['id'],
        planID: PricingPlan['id']
      ) => Promise<string[]>;
    };
    members: {
      read: (
        organizationID: Organization['id'],
        accountId: Account['id']
      ) => Promise<Account>;
      list: (
        organizationID: Organization['id'],
        params: UseMembersFilterParams
      ) => Promise<PaginatedAPIResponse<Account>>;
      addApplicationRoles: (
        accountId: Account['id'],
        newRoles: { roles: ApplicationRole[] }
      ) => Promise<void>;
      addOrganizationRoles: (
        organizationID: Organization['id'],
        accountId: Account['id'],
        newRoles: { roles: OrganizationRole[] }
      ) => Promise<void>;
      deleteOrganizationRoles: (
        organizationID: Organization['id'],
        accountId: Account['id'],
        params: { roles: OrganizationRole[] }
      ) => Promise<void>;
      deleteApplicationRoles: (
        accountId: Account['id'],
        params: { roles: ApplicationRole[] }
      ) => Promise<void>;
      deleteOrganizationMember: (
        organizationID: Organization['id'],
        accountId: Account['id']
      ) => Promise<void>;
    };

    invitations: {
      list: (
        organizationId: Organization['id'],
        params: UseOrganizationInvitationsParams
      ) => Promise<PaginatedAPIResponse<OrganizationInvitation>>;
      create: (
        organizationId: Organization['id'],
        variables: CreateOrganizationInvitationVariables
      ) => Promise<OrganizationInvitation>;
      delete: (
        organizationId: Organization['id'],
        organizationInvitationId: OrganizationInvitation['id']
      ) => Promise<void>;
    };
  };

  /** @deprecated */
  applications: CrudAPI<Application> & {
    read: (applicationID: string) => Promise<Application | undefined>;

    gatewayConfiguration: {
      read: (gatewayConfigurationId: string) => Promise<GatewayConfiguration>;
      list: (
        params: OffsetPaginationParams
      ) => Promise<OffsetPaginated<GatewayConfiguration>>;
      create: (
        params: UseCreateGatewayConfigurationParams
      ) => Promise<GatewayConfiguration>;
      update: (
        params: UseUpdateGatewayConfigurationParams
      ) => Promise<GatewayConfiguration>;
    };
  };

  /** @deprecated */
  accounts: {
    read: (accountID: string) => Promise<Account | undefined>;
    update: (account: Partial<Account>) => Promise<Account>;
    updatePassword: (
      accountID: string,
      { oldPassword, newPassword }: UpdatePasswordParams
    ) => Promise<any>;
  };

  /** @deprecated */
  accessTokens: {
    list: () => Promise<AccessToken[]>;
    create: (variables: CreateAccessTokenVariables) => Promise<AccessToken>;
    delete: (id: AccessToken['id']) => Promise<void>;
  };

  /** @deprecated */
  jwt: {
    create: <Type extends JwtType>(
      variables: JwtParams<Type>
    ) => Promise<LumeoJWT>;
    createSearchToken: () => Promise<LumeoJWT>;
  };

  /** @deprecated */
  pipelines: Omit<
    CRUDEndpoints<Pipeline, UsePipelinesFilterParams>,
    'create' | 'update'
  > & {
    create: (variables: CreatePipelineVariables) => Promise<Pipeline>;
    update: (variables: UpdatePipelineVariables) => Promise<Pipeline>;
    deploy: (deployment: Deployment) => Promise<Deployment>;
    lock: (pipelineID: string) => Promise<void>;
    unlock: (pipelineID: string) => Promise<void>;

    dynamicComponents: {
      list: () => Promise<Component[]>;
    };
    templates: {
      list: (
        filterParams?: UsePipelineTemplatesFilterParams
      ) => Promise<PaginatedAPIResponse<PipelineTemplate>>;
    };
  };

  /** @deprecated */
  deployments: Omit<
    CRUDEndpoints<Deployment, UseDeploymentsFilterParams>,
    'create'
  > & {
    start: (deploymentID: Deployment['id']) => Promise<any>;
    stop: (deploymentID: Deployment['id']) => Promise<any>;
    rename: (
      deploymentID: Deployment['id'],
      name: string
    ) => Promise<Deployment>;
  };

  /** @deprecated */
  streams: CRUDEndpoints<Stream, UseStreamsFilterParams>;
  /** @deprecated */
  cameras: CRUDEndpoints<Camera, UseCamerasFilterParams> & {
    createReferencePipelines: (
      camera: Camera
    ) => Promise<Camera['reference_pipeline_ids']>;
    deleteReferencePipelines: (
      cameraID: string,
      pipelineIDs: string[]
    ) => Promise<Camera['reference_pipeline_ids']>;
  };
  /** @deprecated */
  videoSources: {
    list: <T extends VideoSource = VideoSource>(
      params: UseVideoSourcesFilterParams
    ) => Promise<PaginatedAPIResponse<T>>;
  };
  /** @deprecated */
  snapshot: (source: VideoSource) => Promise<UseSnapshotResponse>;
  /** @deprecated */
  files: {
    create: (file: LumeoUploadFile) => Promise<LumeoFile>;
    read: (fileID: string) => Promise<LumeoFile>;
    updateStatus: (
      fileID: string,
      cloud_status: LumeoFile['cloud_status']
    ) => Promise<void>;
    addTags: (
      fileId: LumeoFile['id'],
      tagIds: Tag['id'][]
    ) => Promise<Tag['id'][]>;
    removeTags: (
      fileId: LumeoFile['id'],
      tagIds: Tag['id'][]
    ) => Promise<Tag['id'][]>;
  };
  /** @deprecated */
  gateways: Omit<
    CRUDEndpoints<Gateway, UseGatewaysFilterParams>,
    'create' | 'list'
  > & {
    list: (
      params: UseGatewaysFilterParams
    ) => Promise<OffsetPaginated<Gateway>>;
    getSpecification: (
      gatewayID: Gateway['id']
    ) => Promise<GatewaySpecification | null>;
    cameras: {
      link: (gatewayID: Gateway['id'], camera: Camera) => Promise<Camera>;
      discover: (
        gatewayID: Gateway['id'],
        requestID: string
      ) => Promise<DiscoveryRequestResponse>;
      getDiscoveryID: (
        gatewayID: Gateway['id']
      ) => Promise<DiscoveryRequestResponse>;
    };
    listLinkedCameras: (gatewayID: Gateway['id']) => Promise<Camera[]>;
    cloud: {
      create: (name: string, type?: 'trial') => Promise<Gateway>;
      start: (gatewayID: Gateway['id']) => Promise<void>;
      stop: (gatewayID: Gateway['id']) => Promise<void>;
      retry: (gatewayID: Gateway['id']) => Promise<void>;
      restart: (gatewayID: Gateway['id']) => Promise<void>;
    };
  };
  /** @deprecated */
  events: {
    list: (
      filterParams?: UseEventsFilterParams & { limit?: number }
    ) => Promise<LumeoEvent[]>;
  };
  /** @deprecated */
  models: Pick<CrudAPI<AIModel, AIModel['id']>, 'list' | 'delete'> & {
    read: (modelID: AIModel['id']) => Promise<AIModel>;
    create: (model: AIModel, files: ModelFiles) => Promise<AIModel>;
    update: (model: UpdateModelParams, files?: ModelFiles) => Promise<AIModel>;
    marketplace: Pick<CrudAPI<AIModel, AIModel['id']>, 'list' | 'delete'> & {
      read: (modelID: AIModel['id']) => Promise<AIModel>;
      /** admin-only */
      create: (model: AIModel, files: ModelFiles) => Promise<AIModel>;
      update: (model: UpdateModelParams, files: ModelFiles) => Promise<AIModel>;
    };
  };

  dashboards: CRUDEndpoints<Dashboard, UseDashboardsFilterParams> & {
    propel: {
      authenticate: () => Promise<PropelToken>;
    };
  };
  /** @deprecated */
  invitations: {
    accept: (variables: InvitationVariables) => Promise<void>;
    acceptAndCreateAccount: (
      variables: AcceptAndCreateAccountVariables
    ) => Promise<void>;
    decline: (variables: InvitationVariables) => Promise<void>;
    hasAccount: (token: string) => Promise<void>;
  };
  /** @deprecated */
  webrtc: {
    getOffer: (streamID: string) => Promise<any>;
    close: (streamID: string, peerID: string) => Promise<any>;
    setAnswer: (streamID: string, peerID: string, data: any) => Promise<any>;
    getCandidates: (streamID: string, peerID: string) => Promise<any>;
    addCandidate: (
      streamID: string,
      peerID: string,
      candidate: { toJSON: () => any }
    ) => Promise<any>;
  };
};
