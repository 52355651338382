import React from 'react';
import classnames from 'classnames/bind';

import { LumeoEventObject, LumeoEventSeverity } from 'types/event';
import { TimeFilterOption } from 'types/filters';

import { useInfiniteEvents } from 'hooks/api/useInfiniteEvents';

import * as PageLayout from 'components/PageLayout';
import { EventsGraph } from 'events/components/Graph/EventsGraph';
import { EventsTable } from 'events/components/Table/Table';
import { FilterView } from 'components/FilterView/FilterView';
import { Text } from 'components/Text/Text';

import styles from './EventsOverview.module.scss';

const c = classnames.bind(styles);

const categoryFilterOptions = [
  { label: 'All', value: '' },
  { label: 'Pipeline Deployment', value: 'deployment' },
  { label: 'Gateway', value: 'gateway' },
  { label: 'Camera', value: 'camera' },
  { label: 'Stream', value: 'stream' },
];

const severityFilterOptions = [
  { label: 'All', value: '' },
  { label: 'Info', value: 'info' },
  { label: 'Warning', value: 'warning' },
  { label: 'Error', value: 'error' },
];

export function EventsOverview() {
  const [scale, setScale] = React.useState<'hour' | 'day'>('hour');

  const [since, setSince] = React.useState<number>();
  const [until, setUntil] = React.useState<number>();
  const [severity, setSeverity] = React.useState<LumeoEventSeverity>();
  const [category, setCategory] = React.useState<LumeoEventObject>();

  const {
    isFetchingNextPage,
    isCompleted,
    data,
    status,
    isFetching,
    fetchNextPage,
  } = useInfiniteEvents({
    since,
    until,
    severities: severity ? [severity] : undefined,
    categories: category ? [category] : undefined,
  });

  const filters = [
    {
      key: 'severity',
      label: 'Severity',
      options: severityFilterOptions,
      onChange: setSeverity,
    },
    {
      key: 'object',
      label: 'Entity',
      options: categoryFilterOptions,
      onChange: setCategory,
    },
  ];

  const loadingInfoRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleScroll() {
      if (
        !isCompleted &&
        !isFetching &&
        isVisibleOnViewport(loadingInfoRef.current as HTMLElement)
      ) {
        fetchNextPage();
      }
    }

    function isVisibleOnViewport(elem: HTMLElement): boolean {
      if (!elem) {
        return false;
      }

      const docViewTop = window.pageYOffset;
      const docViewBottom = docViewTop + window.innerHeight;

      const elemTop = elem.offsetTop;
      const elemBottom = elemTop + elem.offsetHeight;

      return elemBottom <= docViewBottom && elemTop >= docViewTop;
    }

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [isCompleted, isFetching, fetchNextPage]);

  function handleTimeFilterChange(
    _filter: TimeFilterOption | undefined,
    since: number,
    until: number
  ) {
    setSince(since);
    setUntil(until);

    if (until - since > 24 * 60 * 60 * 1000) {
      setScale('day');
    } else {
      setScale('hour');
    }
  }

  return (
    <PageLayout.Root>
      <PageLayout.Container size="full">
        <FilterView
          data={data}
          entity="Events"
          filters={filters}
          timeFilter
          onTimeFilterChange={handleTimeFilterChange}
          disableSearch
        >
          <section className={styles.graph}>
            <EventsGraph unit={scale} />
          </section>
          <EventsTable loading={status === 'loading'} />

          <div className={c('events-info')} ref={loadingInfoRef}>
            {isFetchingNextPage ? (
              <div className="loading-spinner-wrap">
                <div className="loading-spinner"></div>
              </div>
            ) : null}
            {data.length > 0 && isCompleted && <Text>End of list.</Text>}
          </div>
        </FilterView>
      </PageLayout.Container>
    </PageLayout.Root>
  );
}
