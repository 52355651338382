import React from 'react';
import classNames from 'classnames/bind';

import { useGatewayCameras } from 'hooks/api/useGatewayCameras';

import { Box } from 'components/Box/Box';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Text } from 'components/Text/Text';

import { DiscoveredAndLinkedGatewayCameras } from './components/DiscoveredAndLinkedGatewayCameras';
import styles from './LinkGatewayCamera.module.scss';

const c = classNames.bind(styles);

type LinkGatewayCameraProps = {
  gatewayID: string;
};

export function LinkGatewayCamera({ gatewayID }: LinkGatewayCameraProps) {
  const { cameras, isLoading, isDiscovering, isCameraLinked, discover } =
    useGatewayCameras(gatewayID);

  React.useEffect(() => {
    if (isDiscovering || isLoading) {
      return;
    }

    if (gatewayID) {
      discover();
    }
    // eslint-disable-next-line
  }, [gatewayID]);

  if (!gatewayID) {
    return null;
  }

  if (isDiscovering || isLoading) {
    return (
      <>
        <Box className={c('discovering-cameras-box')}>
          <div className="loading-spinner xsmall" />
          <div className={c('discovering-cameras-text')}>
            <Heading level="3">Discovering cameras</Heading>
            <Text type="paragraph">
              Lumeo is searching for ONVIF and USB cameras accessible by this
              gateway...
            </Text>
          </div>
        </Box>

        <FormMessage intent="info" icon="info">
          If your IP camera cannot be discovered automatically, but it is on the
          same subnet, check to ensure you have enabled ONVIF in your camera's
          settings.
        </FormMessage>
      </>
    );
  }

  return (
    <DiscoveredAndLinkedGatewayCameras
      gatewayID={gatewayID}
      cameras={cameras}
      discover={discover}
      isCameraLinked={isCameraLinked}
    />
  );
}
