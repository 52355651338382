import React from 'react';
import classNames from 'classnames/bind';
import { Slot } from '@radix-ui/react-slot';

import styles from './Text.module.scss';

const c = classNames.bind(styles);

type CommonTextProps = React.PropsWithChildren<{
  className?: string;
  align?: 'start' | 'center' | 'end';
  intent?: 'danger';
  type?: 'paragraph';
  size?: 'large' | 'small' | 'xsmall' | 'xxsmall';
}>;

export type InlineTextProps = CommonTextProps & {
  asChild?: never;
  inline?: true;
};

export type SlotTextProps = CommonTextProps & {
  inline?: never;
  asChild?: true;
};

export type TextProps = InlineTextProps | SlotTextProps;

function Text(
  {
    intent,
    type,
    size,
    align,
    className,
    children,
    asChild,
    inline,
    ...props
  }: TextProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const defaultCmp = inline ? 'span' : 'p';
  const Cmp = (asChild ? Slot : defaultCmp) as any;

  return (
    <Cmp
      className={c('text', type, size, align, className, {
        [`theme ${intent}`]: intent,
      })}
      {...props}
      ref={ref}
    >
      {children}
    </Cmp>
  );
}

const forwardRef = React.forwardRef<HTMLElement, TextProps>(Text);

export { forwardRef as Text };
