import LumeoEvent from 'types/event';

import { Text } from 'components/Text/Text';

export type EventsTableDateCell = {
  event: LumeoEvent;
};

export function EventsTableDateCell({ event }: EventsTableDateCell) {
  if (!event) {
    return null;
  }

  const { event_ts } = event;

  const eventDate = new Date(event_ts).toLocaleString('en-US', {
    dateStyle: 'medium',
  });

  const eventTime = new Date(event_ts).toLocaleString('en-US', {
    timeStyle: 'medium',
  });

  return (
    <>
      <Text inline>{eventDate}</Text>
      <br />
      <Text inline>{eventTime}</Text>
    </>
  );
}
