import React from 'react';
import classNames from 'classnames/bind';

import { IconType } from './types/Icon';

import styles from './Icon.module.scss';

const c = classNames.bind(styles);

export type IconProps = React.HTMLAttributes<SVGSVGElement> & {
  name: IconType;
  className?: string;
  size?: 'small' | 'xsmall' | 'medium' | 'large';
};

function Icon(
  { name, className, size = 'medium', ...rest }: IconProps,
  ref: React.ForwardedRef<SVGSVGElement>
) {
  const props = { className: c('icon', className, size), ...rest };

  return (
    <svg {...props} ref={ref}>
      <use xlinkHref={`/sprite.svg#${name}`} />
    </svg>
  );
}

const forwardedRef = React.forwardRef(Icon);

export { forwardedRef as Icon };

export type { IconType };
